import React, { Component, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes, { any } from 'prop-types';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import { initValues, processJSONParameter } from '../common/configTools';
import {
  unescapeHTML,
  profanityCheck,
  fireAnalyticsEvent, fireNonRFISubmitEvent
} from '../CommonComponents';
import styles from '../inline/SubscribeStyles';
import checkmark from '../layout/images/checkmark.svg';
import axios from 'axios';
import Button from '../layout/MaterialComponents/button/Button';
import AddressAutoComplete from '../layout/MaterialComponents/UOPAutoCompleteSmartyStreets';
//import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SelectField from '../UopxNativeSelectField';
import { FlareSharp } from '@mui/icons-material';

var disableField = true;
var prevIssueNo;
var issueNoData = []; 
/*
let issueNoData = [
  { label: "Issue 1", value: "Issue 1" },
  { label: "Issue 2", value: "Issue 2" },
]; 
*/

class AlumniChroniclesMagazine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: initialEmailFormValues,
      variant: props.variant,
      issueNoData: any,
      message: null,
      currentStep: 1,
      street: '',
    };
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
    };
  }

  componentDidMount() {
    const { props } = this;
    let { initialValues } = this.state;
    initialValues.requestingUrl = window.location.href;
    initValues(initialValues, this.props);
    this.setState({ initialValues });
    const { currentStep } = this.state;
    formStep = currentStep;
	  formType = 'Non-RFI';
	  formName = 'AlumniChroniclesMagazine';
    if (window.pageData && window.pagePath) {
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
    }
   
    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
    if (window.location.host !== 'www.phoenix.edu') {
        baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
    }

    // fetch issueNoData
    fetch(baseUrl + '/content/dam/edu/microservices/feed/issueData.json', { method: 'GET'})
    .then(response => {return response.json();})
    .then(data => {issueNoData = data; console.log("issueNoData", issueNoData); this.issueJsonDataUpdate();})
    .catch(function (error) {
        console.log(error);
    }); 
  

    fetch(baseUrl + '/services/contacts/v1/validate/zipcode?zipcode=85040', { method: 'HEAD' })
    .then(response => {
        if (response.status === 424){
            disableField = false;
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  handlePhoneNumberBlur = (value) => {
    this.ref.form.current.values.phoneNumber = value;
  }
  
  issueJsonDataUpdate(){
    if (issueNoData.length > 0) {
      this.forceUpdate();
    }
  }

  dropdownChange = (name, value) => {
    const {initialValues} = this.state;
    if (name === 'IssueNo') {
        if (value && prevIssueNo !== value) {
            prevIssueNo = value;
            initialValues.IssueNo = value;
        }
    }
  /*  else if (name === 'bePhoenix') {
        if (value && prevbePhoenix !== value) {
          //  fireFOS_DlEvent(name, value, formName, formType);
            prevbePhoenix = value;
            initialValues.bePhoenix = value
        }
    } */
  }
  
  handleSubmit = (values) => {
  //  hashValues(values);
    values.fullName = (values.fullName).trim();
    this.setState({ initialValues: values });
    const headers = {
        'Content-Type': 'application/json',
    };
    let URL = 'https://www.phoenix.edu/request/sendmail';
    const data = JSON.stringify(values);
    console.log ("values submitted", values);
    
    axios.post(URL, data, {
        headers,
      })
    .then((response) => {
        if (response.data.msg === 'success'){
            console.log("Email sent, awesome!");
            this.setState({
              initialValues: values,
              isSubmit: true,
              message: null,
            });    
        }else if(response.data.msg === 'fail'){
            console.log("Oops, something went wrong. Try again")
        }
    })
    .catch(error => {
    console.log (error.message);   
    });
    
    fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
    //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
}

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin !== true) {
      this.setState({ rfi_begin: true });
    }
  }

  hasMarketingInfoConsent = (event) => {
    const { checked } = event.target;
    this.ref.form.current.values.MarketingInfoConsent = checked;
  }

  hasAlumniStoryConsent = (event) => {
    const { checked } = event.target;
    this.ref.form.current.values.AlumniStoryConsent = checked;
  }

  validateForm(values) {
    const errors = {};
    //console.log ("values.address", !values.address);
    if (!values.fullName) {
      errors.fullName = 'First and Last name requires a value';
    } else if (values.fullName.trim().indexOf(' ') === -1) {
      errors.fullName = 'Please enter a First and Last name';
    } else if (!profanityCheck(values.fullName)) {
      errors.fullName = 'First and Last name appears invalid';
    }
    if (!values.IssueNo) {
      errors.IssueNo = 'Issue No. requires a value';
    }
    if (!values.address) {
      this.state.street?values.address = this.state.street:
      errors.address = 'Address requires a value';
    }
    
/*    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    } */
  //  console.log('errors', errors, values);
    return errors;
  }

  getAddressInfo(e) {
    const getStreet = async (inputText) => {
        let response = await fetch(
            `/services/contacts/v2/suggest/address?prefix=${encodeURI(inputText)}`,
        );
        if (response.status === 200) {
            let responseData = await response.json();
          //  console.log(responseData);
            const newZip = responseData?.suggestions[0]?.zipcode;
            const newAddress = responseData?.suggestions[0]?.text;
            const address_zip = newAddress + " " + newZip;
            const oldStreet = this.state.street;

            // Entering a new address
            if (oldStreet !== address_zip) {
                this.setState({
                    street: address_zip,
                });
            }
        }
    }
    if (e !== null) {
        getStreet(e);
    } else {
        this.setState({
            street: '',
        });
    }
  }

  render() {
    const { classes } = this.props;
    // console.log('SubEmail rendering...');
    const { isSubmit, initialValues } = this.state;
    return (
      <div className={classes.container}>
        {/*    <UOPHeader />    */}
        <div className={classes.root}>
          <Grid container justifyContent="center" className="mobileFormContainer" ref={this.ref.container}>
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handleSubmit(values)}
              validate={values => this.validateForm(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="AlumniChroniclesMagazine-form"
                style={{ position: 'relative' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid container spacing={1}>
                  <Grid item md={12} sm={12} lg={12} xs={12}>
                    <h2 className="newOneForm-title" style={{ fontSize: '30px' }}>
                      {this.state.initialValues.title}
                    </h2>
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} xs={12}>
                    <div className="newOneForm-SubTitle" style={{ fontFamily: 'inherit' }}>
                      {this.state.initialValues.description}
                    </div>
                  </Grid>
                  {!isSubmit
                    ? (
                      <>
                        <Grid item md={12} sm={12} lg={12} xs={12}>
                          {this.state.message}
                          <Typography
                            variant='body2'
                            component='div'
                            className="reqinfo"
                            style={{ marginBottom: '0' }}
                          >
                            * Required information
                          </Typography>
                        </Grid>
                          <Grid item md={6} sm={6} lg={6} xs={12} className="margin-OneStepForm">
                            <MaterialInput
                              name="fullName"
                              id="fullName"
                              type="text"
                              label="First and Last Name *"
                              ariaRequired="true"
                              ariaLabel="Enter your full name"
                              variant="outlined"
                              onClick={this.handleAnalyticsRFIFormStart}
                            />
                          </Grid>
                          <Grid item md={6} sm={6} lg={6} xs={12} className="margin-OneStepForm">
                            <MaterialInput
                              name="emailAddress"
                              id="emailAddress"
                              label="Email Address"
                              ariaLabel="Enter Email address"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} sm={6} lg={6} xs={12} className="margin-OneStepForm" style={{ width: 'auto', position:'relative' }}>                
                            {disableField &&     
                              <AddressAutoComplete className="uopx-native-select" id='address' name='address' label='Current Address' required={true}
                                onChange={addressInfo => this.getAddressInfo(addressInfo)}
                                street={this.state.street}
                                ariaRequired={true}
                              /> 
                            }
                            {disableField === false && 
                              <MaterialInput
                                name="address"
                                id="address"
                                label="Enter complete current Address *"
                                ariaRequired="true"
                                ariaLabel="Current Address"
                                variant="outlined"
                              />
                            }
                          </Grid>
                          <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm">
                            <SelectField
                              id="IssueNo"
                              name="IssueNo"
                              label="Issue No. *"
                              data={issueNoData}
                              className="MuiFormControl-fullWidth"
                              ariaRequired="true"
                              formRef={this.ref.form}
                              onChange={e => this.dropdownChange('IssueNo', e.target.value)}
                            ></SelectField>
                          {/* roleChange() */}
                          </Grid>
                          <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">
                              <FormikCheckbox type="checkbox" id="MarketingInfoConsent" name="MarketingInfoConsent" className="cbe-checkbox" onChangeCallback={this.hasMarketingInfoConsent} label={unescapeHTML('By selecting this box, I consent to University of Phoenix sending me notifications, updates, requests for information, and/or marketing information concerning University of Phoenix. View our <a href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy</a> for more information.')}>
                              </FormikCheckbox>
                          </Grid>
                          <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">
                              <FormikCheckbox type="checkbox" id="AlumniStoryConsent" name="AlumniStoryConsent" className="cbe-checkbox" onChangeCallback={this.hasAlumniStoryConsent} label={unescapeHTML('By selecting this box, I consent to be contacted to share my story on becoming a Phoenix.')}>
                              </FormikCheckbox>
                          </Grid>
                          <Grid item md={6} sm={6} lg={6} xs={12}>
                            <Button
                              size="large"
                              type="submit"
                            >
                              Get my copy
                            </Button>
                          </Grid>
                      </>
                    )
                    : (
                      <>
                        <Grid item md={12} sm={6} lg={12} xs={12} className={classes.thanksContainer}>
                          <div className='thanksHeader' style={{ fontWeight: '700' }}>
                            <img src={checkmark} alt="Checked Circle" />
                            Thanks for Form Submission
                          </div>
                        </Grid>
                      </>
                    )}
                </Grid>

                <FormikErrorFocus
                  offset={-50}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </Form>
            </Formik>
          </Grid>
        </div>
      </div>
    );
  }
}
const initialEmailFormValues = {
  fullName: '',
  emailAddress: '',
  address: '',
  title: 'Get a print copy',
  description: "Reading a physical magazine can bring the stories within it to life. Supply is limited, so claim yours today! We can mail it anywhere in the continental U.S.",
  requestingUrl: '',
  IssueNo: '',
  formName:'AlumniChroniclesMagazine',
  MarketingInfoConsent: false,
  AlumniStoryConsent: false,
};

AlumniChroniclesMagazine.defaultProps = {
  classes: {},
};

AlumniChroniclesMagazine.propTypes = {
  classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(AlumniChroniclesMagazine);
