import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import App from './App';
//import RFIInlineForm from './components/inline/RFIInlineForm';
import RFIDesktopEmbed from './components/RFIDesktopEmbed';
import ReEntryContactUs from './components/email-us/ReEntryContactUs';
import AlumniStory from './components/email-us/AlumniStory';
import AlumniContact from './components/email-us/AlumniContactForm';
import UopxContact from './components/email-us/UopxContact';
import ReferAFriend from './components/refer-a-friend/ReferFriend';
import SubscribeEmailForm from './components/inline/SubscribeEmailForm';
import Abandoned from './components/email-us/Abandoned';
import BusinessGuideAbandonment from './components/email-us/BusinessGuideAbandonment';
import BusinessGuideInline from './components/email-us/BusinessGuideInline';
import BusinessGuide from './components/email-us/BusinessGuide';
import RFIAbandoned from './components/email-us/EmailSubscribe';
import ScholarshipsInline from './components/email-us/ScholarshipsInline';
import ScholarshipBladeModal from './components/email-us/ScholarshipBladeModal';
import ScholarshipExitModal from './components/email-us/ScholarshipExit';
import ZeroPartyModal from './components/email-us/ZeroPartyModal';
import EmailSubscribeFooter from './components/email-us/EmailSubscribeFooter';
import SummitEmail from './components/email-us/SummitEmail';
import AlumniBusinessForm from './components/email-us/AlumniBusinessForm';
import White2Step from './components/inline/White2Step';
import DynamicRFIInlineForm from './components/inline/DynamicRFIInlineForm';
import EmployerDynamicRFIInlineForm from './components/inline/EmployerDynamicRFIInlineForm';
import AlumniChroniclesMagazine from './components/email-us/AlumniChroniclesMagazine';
import CommunityCollegeTransferForm from './components/inline/CommunityCollegeTransferForm';
import EmployerReferralForm from './components/EmployerReferralForm';
import PaidMediaEmployerRFIInlineForm from './components/inline/PaidMediaEmployerRFIInlineForm';
import TransferCreditCollegeGuideForm from './components/inline/TransferCreditCollegeGuideForm';
import TransferCreditGuideAbandonment from './components/inline/TransferCreditGuideAbandonment';
import WSGEmailPhoneCaptureForm from './components/email-us/EmailPhoneSubscribeWSG';
import ScheduleACall from './components/inline/ScheduleACall';
import GatedContentForm from './components/email-us/GatedContentForm';
import Phoenix76  from './components/email-us/Phoenix76';

// Register Duet Date Picker
defineCustomElements(window);

let targetElm;
switch (window.RFI_RENDER_MODE) {
  default:
    targetElm = document.getElementById('root');
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      targetElm,
    );
    break;

  case 'callback':
    const ComponentList = {
      React,
      ReactDOM,
      AlumniStory,
      AlumniContact,
      AlumniBusinessForm,
      AlumniChroniclesMagazine,
      UopxContact,
      ReferAFriend,
      ReEntryContactUs,
      SubscribeEmailForm,
      SummitEmail,
      EmployerDynamicRFIInlineForm,
      PaidMediaEmployerRFIInlineForm,
      White2Step,
      DynamicRFIInlineForm,
      CommunityCollegeTransferForm,
      EmployerReferralForm,
      RFIDesktopEmbed,
      EmailSubscribeFooter,
      Abandoned,
      BusinessGuideAbandonment,
      BusinessGuideInline,
      BusinessGuide,
      ScheduleACall,
      ScholarshipsInline,
      ScholarshipExitModal,
      ZeroPartyModal,
      GatedContentForm,
      Phoenix76,
      WSGEmailPhoneCaptureForm
    };
    <React.Suspense fallback={<div>Loading...</div>}>
      {document.dispatchEvent(new CustomEvent('rfi-wrapper-available', { detail: ComponentList }))}
    </React.Suspense> 
    //  document.dispatchEvent(new CustomEvent('rfi-wrapper-available', { detail: ComponentList }));
  break;

{/*
  case 'rfi-inline-widget':
    targetElm = document.getElementById('rfi-inline-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <RFIInlineForm
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;
    */}

  case 'dynamic-inline-widget':
      targetElm = document.getElementById('dynamic-inline-widget');
      ReactDOM.render(
        <div className="RFIReactApp">
          <React.Suspense fallback={<div>Loading...</div>}>
          <DynamicRFIInlineForm
            {...targetElm.dataset}
          />
          </React.Suspense>
        </div>,
        targetElm,
      );
      break;

      case 'paidmediaemployer-inline-widget':
      targetElm = document.getElementById('paidmediaemployer-inline-widget');
      ReactDOM.render(
        <div className="RFIReactApp">
          <React.Suspense fallback={<div>Loading...</div>}>
          <PaidMediaEmployerRFIInlineForm
            {...targetElm.dataset}
          />
          </React.Suspense>
        </div>,
        targetElm,
      );
      break;

  case 'employer-inline-widget':
    targetElm = document.getElementById('employer-inline-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <White2Step
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;
{/*      
  case 'employer-check-inline-widget':
    targetElm = document.getElementById('employer-check-inline-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <Employer2StepChecked
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;
*/}    
  case 'email-us-widget':
    targetElm = document.getElementById('email-us-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <ReEntryContactUs
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;

  case 're-entry-contact-us-widget':
    targetElm = document.getElementById('re-entry-contact-us-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <ReEntryContactUs
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;

  case 'uopx-contact-us-widget':
    targetElm = document.getElementById('uopx-contact-us-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <UopxContact
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;

  case 'alumni-story-widget':
    targetElm = document.getElementById('alumni-story-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <AlumniStory
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;

  case 'alumni-contact-widget':
    targetElm = document.getElementById('alumni-contact-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <AlumniContact
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;

  case 'subscribe-email-widget':
    targetElm = document.getElementById('subscribe-email-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <SubscribeEmailForm
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;

  case 'subscribe-email-variant-widget':
    targetElm = document.getElementById('subscribe-email-variant-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <SubscribeEmailFormVariant
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
  break;

  case 'summit-email-widget':
    targetElm = document.getElementById('summit-email-widget');
    ReactDOM.render(
      <div className="RFIReactApp">
        <SummitEmail
          {...targetElm.dataset}
        />
      </div>,
      targetElm,
    );
    break;
  
  case 'scholarshipinline-widget':
  targetElm = document.getElementById('scholarshipinline-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <ScholarshipsInline
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;
  
  case 'schedule-a-call':
  targetElm = document.getElementById('schedule-a-call');
  ReactDOM.render(
    <div className="RFIReactApp">
      <ScheduleACall
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'scholarshipblade-widget':
  targetElm = document.getElementById('scholarshipblade-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <ScholarshipBladeModal
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'scholarshipexit-widget':
  targetElm = document.getElementById('scholarshipexit-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <ScholarshipExitModal
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'zeroparty-modal':
  targetElm = document.getElementById('zeroparty-modal');
  ReactDOM.render(
    <div className="RFIReactApp">
      <ZeroPartyModal
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'rfiabandoned-widget':
  targetElm = document.getElementById('rfiabandoned-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <RFIAbandoned
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'abandoned-widget':
  targetElm = document.getElementById('abandoned-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <Abandoned
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'businessguideinline-widget':
  targetElm = document.getElementById('businessguideinline-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <BusinessGuideInline
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'businessguide-widget':
  targetElm = document.getElementById('businessguide-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <BusinessGuide
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'businessguideabandonment-widget':
  targetElm = document.getElementById('businessguideabandonment-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <BusinessGuideAbandonment
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;
  
  case 'transfercreditguideblade-widget':
  targetElm = document.getElementById('transfercreditguideblade-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <TransferCreditCollegeGuideForm
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'transfercreditguideabandonment-widget':
  targetElm = document.getElementById('transfercreditguideabandonment-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <TransferCreditGuideAbandonment
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'gatedContent-widget':
  targetElm = document.getElementById('gatedContent-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <GatedContentForm
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;

  case 'edu-footer-widget':
  targetElm = document.getElementById('edu-footer-widget');
  ReactDOM.render(
    <div className="RFIReactApp">
      <EmailSubscribeFooter
        {...targetElm.dataset}
      />
    </div>,
    targetElm,
  );
  break;
}
