import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; // prop-types
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import { addDays, format, isBefore } from 'date-fns';
import isToday from 'date-fns/isToday';
import rfiFormInitialValues from './layout/FormModels/RFIFormInitialValues';
import MaterialRadio from './layout/MaterialComponents/MaterialRadio';
import styles from './layout/styles/MobileFormStyles';
import SelectField from './UopxNativeSelectField';
import MaterialInput from './layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from './layout/MaterialComponents/MaterialPhoneNumber';
import { validateOneStep } from './layout/MobileFormValidation';
import { unescapeHTML, processJSONParameter } from './common/configTools';
import getIPAddress, { conditionalCBE, fireAnalyticsEvent, fireCampaignEvent, fireCampaignRadioEvent, fireFOS_DlEvent, getDegreeLevel, loadCookiesData, pageNameByPath, roleChange, submitForm, updatePrePopCookieValue } from './CommonComponents';
import { apptsAvailable, courseCategoryDropdown, degreeLevel, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, radioOption } from './common/constants';
import {UOPDatePicker} from './layout/MaterialComponents/UOPDatePicker';
import TimePicker from './layout/MaterialComponents/CustomTimePicker';
import UOPHeader from './layout/UopHeader';
import Footer from './layout/Footer';
import '../../node_modules/@duetds/date-picker/dist/duet/themes/default.css';
import moment from 'moment';
import Button from './layout/MaterialComponents/button/Button';
import ZipCode from './layout/MaterialComponents/ZipCode';
import FormikCheckbox from './layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from './layout/MaterialComponents/checkbox/SingleCheckbox';
import { ThreeSixtyTwoTone } from '@mui/icons-material';
import {dataLayer} from './common/dataLayer';
import skillsblade from './layout/images/skillsblade.svg';

const formRef = {};
var feedback = {};
let prevFOS, prevDegreeLevel, prevState,prevCourseCategory;
let courseCategoryFlag = false;
const disclaimerText = rfiFormInitialValues.disclaimer;
let isSavingsExplorer = false;
let subHeader = "Answer a few questions and an enrollment representative will connect with you to chat about options that can help you save time and money on your degree.";
let dateHasBeenChosen = false;
let dateValue = null;
let timeValue = null;
let isDateValid = false;
let hasErrorMsg = false;
let hasErrorTimeMsg = false;
let today = new Date();
let endDate = addDays(today, 5);
let errors = {};
const timeErrorID = 'timeError';
const dateErrorID = 'dateError';
today = today.toISOString().split('T')[0];
endDate = endDate.toISOString().split('T')[0];
let scheduledContactDateLabel = "Schedule contact date * (mm/dd/yyyy)";
const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};

const scheduleACallRadioOption = [
    { value: "Yes", label: "Yes! Let's set up a call." },
    { value: "No", label: "No. Just call me during business hours." },
  ];

class RFIDatePicker extends Component {
    constructor(props) {
        super(props);        
        this.initialState = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            formRef: null,
            variant: props.variant,
            feedback: any,
	        activeStep: 1,
            isScheduledCallRadio: false,
            fieldOfStudyOptions: processJSONParameter([...dynamicFieldOfStudyDropdownVariant]),
            degreeLevel: processJSONParameter([...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter([...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
        };
        this.state = this.initialState;
        this.ref = {
            field: {
                date: React.createRef(),
                time: React.createRef(),
            }
        };
    }

    componentDidMount() {
        window.pagePath = "page-dc_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Desktop`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "RFI schedule call desktop - 1 step";
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        if (document.referrer.indexOf('/cost-savings/ways-to-save/results')>0){
            isSavingsExplorer=true;
            subHeader="Answer a few questions and an enrollment representative will reach out soon.";
        }
        //initialValues.date= new Date();
        //initialValues.date=null;
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        this.handleZipcodeChange(initialValues.postalCode,initialValues.stateProvince);
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        const { activeStep } = this.state;
        formStep = activeStep;

        // fetch feedback
    /*    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }

        fetch(baseUrl + '/services/feedback/v1/summary',
            {
                method: 'GET',
            })
            .then(response => response.json())
            .then(data => feedback = data)
            .catch(function (error) {
                console.error(error);
            }); */
	    if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer();
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
        }
        if (name === 'isScheduledCall') {
            formRef.current.values.isScheduledCall = value;
            if(value === "Yes"){ 
                this.setState({isScheduledCallRadio: true});
                formRef.current.values.flex_rfiCallback = true;
            }
            if(value === "No") {
                this.setState({isScheduledCallRadio: false});
                formRef.current.values.flex_rfiCallback = false;
            }
            if (this.state.not_abandoned !== true) {
                this.setState({ not_abandoned: true });
            }
            if (this.state.scheduledCallRadio !== true) {
                fireCampaignRadioEvent('rfi_schedule_call', value, formName, formType);
                this.setState({ scheduledCallRadio: true });
            }
        }
    };

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }
                else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
            }
        }
        else if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                initialValues.degreeLevel = value
                this.validate(initialValues);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }
    
    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
          var optionExists = newDegreeLevelOptions.some((e)=>this.state.initialValues.degreeLevel===e.value);
          //if prev degree level doesnt exist, reset
          if (!optionExists) formRef.current.setFieldValue('degreeLevel', '')
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);

            if (state && prevState !== state) {
                prevState = state;
            }
            this.updateAndNotify(state);
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    isCBEConsent = (event, checked) => {
        console.log(event); 
        console.log(checked)
        formRef.current.values.isCBE = checked;        
    }

    onDatePicked = (date, formProps) => {
    //  console.log ("onDatePicked date is ", date);
        let selectedDate = new Date(date);
        //formProps.values.date = selectedDate;
        let timeMin = new Date(selectedDate).getMinutes();
        let currentHour = new Date().getHours();
        let currentMin = new Date().getMinutes();
        if (selectedDate !== null){
            if (isToday(new Date(selectedDate))) {
                if (currentHour >= 17 || (currentHour >= 16 && currentMin > 30)){
                    selectedDate = addDays(new Date(), 1);
                    selectedDate = new Date(selectedDate).setHours(9,0);
                } else if (currentHour <= 8 && currentMin < 30) {
                    //selectedDate = addDays(new Date(), 1);
                    selectedDate = new Date(selectedDate).setHours(9,0);
                } else {
                    if (isBefore(new Date(selectedDate), new Date())) { selectedDate = new Date(new Date().setMinutes(timeMin+30));}
                }
            }
            formProps.setFieldValue("date", new Date(selectedDate).toLocaleDateString("en-US"));
            formProps.validateForm();
        }
    };

    onTimePicked = (time, formProps) => {
        formProps.setFieldValue("time", time);
        console.log("time", time);        
        timeValue=time;
        formRef.current.values.time=timeValue;
        validateDate(dateValue, timeValue);
    };

    handleSubmit = (values) => {
        //console.log ("handleSubmit date value is ", dateValue);
        //console.log ("handleSubmit time value is ", values.time);    
    
        if (dateValue){
            values.date=dateValue;
        }
        if(values.time) {
            let date = new Date(values.date).toLocaleDateString("en-US");
            let datetime = new Date(`${date} ${values.time}`).toLocaleString('en-US', { hour12: true });
            values.date = datetime;
            values.date = moment(values.date).format('YYYY-MM-DDTHH:mm:ss');
            console.log ("handleSubmit date and time of scheduled call is ", values.date); 
        }
        if (((values.fieldOfStudy === 'technology' && values.degreeLevel !== "nondegree") || values.degreeLevel === 'masters')) {
            if (values.date && values.isScheduledCall === 'Yes')  {
                let isoDate = new Date(values.date).toISOString().split('.')[0]+"Z";
            //    console.log ("handleSubmit isodate and time of scheduled call is ", isoDate);
                values.flex_rfiCallbackDateTime = isoDate;
            //    values.flex_rfiCallbackDateTime = values.date;
                values.flex_RFI_Callback__c = 'rfiCallback-true ' + isoDate;
                values.flex_rfiCallbackTimezone = new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4);
            //    console.log ("isodate and time of scheduled call is ", values.flex_rfiCallbackDateTime , " and system timezone where scheduling call is ", values.flex_rfiCallbackTimezone);
                fireCampaignEvent('rfi_schedule_call', values.isScheduledCall, formName, formType);    
            } else if (values.isScheduledCall === 'No') {
                fireCampaignEvent('rfi_schedule_call', values.isScheduledCall, formName, formType);
                values.flex_RFI_Callback__c = 'rfiCallback-false';
            } else if (((values.fieldOfStudy === 'technology' && values.degreeLevel !== "nondegree") || values.degreeLevel === 'masters'))  {
                values.flex_RFI_Callback__c = 'rfiCallback-blank';
                fireCampaignEvent('rfi_schedule_call', 'NA', formName, formType);
            }
        }
        if (document.activeElement.name === "submit") {
            this.setState({ initialValues: values });
        //    values = mapAdditionalFields(values);
        //    submitForm(values);
            submitForm(values, this.props);
        }
    }

    validate(values) {
        errors = validateOneStep(values);
        //let time;
        if(this.isAScheduledCall(values)) {
        //    console.log("inside this.isAscheduledCall");
            /* if(values.time && values.time._d){
                time = moment(values.time._d).format("LT");
                console.log(time);
            } */       
            if(!formRef?.current?.values.date){
                errors.date = 'Scheduled contact date requires a value';   
                document.getElementById(dateErrorID).innerHTML = 'Scheduled contact date requires a value';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');    
            }
            if (!formRef?.current?.values.time || formRef?.current?.values.time == null){
                errors.time = 'Scheduled contact time requires a value';
                document.getElementById(timeErrorID).innerHTML = `Scheduled contact time requires a value`;
                document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(timeErrorID).classList.add('Mui-error');
                document.getElementById('timePicker').classList.add('uopx-wrapper--error');
            }
            let errorDateMsg;
            let errorTimeMsg;
            if(hasErrorMsg === true) {
                errorDateMsg = document.getElementById('dateError').innerHTML;
            }
            if(hasErrorTimeMsg === true) {
                errorTimeMsg = document.getElementById('timeError').innerHTML;
            }
            if (errorDateMsg) errors.date = errorDateMsg;
            if (errorTimeMsg) errors.time = errorTimeMsg;
        }
        return errors;
    }

    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
        } = this.state;
        return (
            <div>
                <UOPHeader />
                <main className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						<div className="newOneForm-Eyebrow">UNIVERSITY OF PHOENIX</div>
						{isSavingsExplorer?
                            <Grid item md={12} sm={12} lg={7} xs={12}><h1 className="newOneForm-title" role="heading" aria-level="1">Every dollar counts. Share your info so we can talk about saving options.</h1></Grid>:
                            <Grid item md={12} sm={12} lg={8} xs={12}><h1 className="newOneForm-title" role="heading" aria-level="1">We'd love to get to know you — and your goals</h1></Grid>
                        }
						</Grid>
                        <Grid item md={12} sm={12} lg={7} xs={12} >
                            <div className="newOneForm-SubTitle">{subHeader}</div>
                            <Typography
                                variant='body2'
                                component='div'
                                className="reqinfo"
                            >
                                * Required information
                            </Typography>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.validate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Request For Information"
                                                  style={{ position: 'relative' }}
                                                  onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container>
                                               <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                               <SelectField
                                                    id="fieldOfStudy"
                                                    name="fieldOfStudy"
                                                    label="Area of study *"
                                                    data={dynamicFieldOfStudyDropdownVariant}
                                                    disabled={props.values.fosisdisable || false}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("fieldOfStudy", props.values.fieldOfStudy)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                 {conditionalRadio(props.values.fieldOfStudy) && <>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} row={true} className="selectRadioLabel" onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                    </Grid>
                                                    </>}
                                                    {props.values.fieldOfStudy !== "nondegree" && <>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                 <SelectField
                                                    id="degreeLevel"
                                                    name="degreeLevel"
                                                    label="Degree level *"
                                                    data={this.state.degreeLevel}
                                                    className="MuiFormControl-fullWidth"
                                                    ariaRequired="true"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                </>}
                                                {props.values.fieldOfStudy === "nondegree" && <>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <SelectField
                                                        id="flex_Course_Category__c"
                                                        name="flex_Course_Category__c"
                                                        label="Course category *"
                                                        data={courseCategoryDropdown}
                                                        className="MuiFormControl-fullWidth"
                                                        formRef={formRef}
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                        onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                    ></SelectField>
                                                    {roleChange()}
                                                </Grid>
                                                </>}
                                                 {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">                                     
                                                        <SingleCheckbox
                                                                className="cbe-checkbox"
                                                                id="isCBE"
                                                                label={unescapeHTML(this.getCBEText())}
                                                                ariaLabel={unescapeHTML(this.getCBEText())}
                                                                checkboxName="isCBE"
                                                                parentCallback={this.isCBEConsent}
                                                            />
                                                    </Grid>
                                                </>}
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                 <MaterialInput name="firstName" id="firstName"
                                                    label="First name *" ariaRequired="true" ariaLabel="Enter First name"  />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="lastName" id="lastName"
                                                    label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialPhoneNumber name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                </Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-describedby="Enter Zip code" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef}></ZipCode>
												</Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className= 'selectRadioLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
												 </Grid>
                                                 
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                   <div style={{fontSize:"14px", fontWeight:"normal", marginBottom:'18px'}}>You might be <strong>eligible for tuition savings</strong> if your employer has an alliance with us. Talk to your enrollment representative about it — as well as all the other ways you can <strong>save time and money</strong> on your degree.</div>
                                                </Grid>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                     <div className="employer">
                                                        <MaterialInput name="flex_Employer_Disclosed_on_RFI__c" id="flex_Employer_Disclosed_on_RFI__c"
                                                            label="Employer name (optional)" ariaLabel="Employer name (optional)"  />
                                                    </div>
                                                 </Grid>
                                                 {((props.values.fieldOfStudy === 'technology' && props.values.degreeLevel !== "nondegree") || props.values.degreeLevel === 'masters') && props.values.source !== 'EMLP' && <>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm call-break"></Grid>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm schedule-call">
                                                    <MaterialRadio value={formRef?.current?.values.isScheduledCall} row={true} className="selectRadioLabel" onChange={this.handleChange} items={scheduleACallRadioOption} label="We're available for a call Monday through Saturday. Would you like to schedule time this week to talk?" name='isScheduledCall' ariaLabel="We're available for a call Monday through Saturday. Would you like to schedule time this week to talk?"></MaterialRadio>
                                                 </Grid>
                                                 </> }
                                                 {this.isAScheduledCall(props.values) &&  <>                                                
                                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                                        <fieldset id="dateTimeFieldSet" style={{borderStyle:'none'}}>
                                                        
                                                        <legend>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <Typography
                                                            variant='body2'
                                                            component='div'
                                                            className= 'selectRadioLabel'
                                                            id="scheduleCallText"
                                                            style={{marginBottom:'18px'}}
                                                        >
                                                            Great! When should we contact you?
                                                        </Typography>
                                                            </Grid>
                                                        </legend>                                                        
                                                        <Grid id='dateGrid' item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm"> 
                                                        <div id='datePicker' className="uopx-wrapper uopx-wrapper--default">
                                                        <label htmlFor="date" aria-label="Schedule Contact Date" required>{scheduledContactDateLabel}</label>
                                                            <UOPDatePicker
                                                                identifier="date" 
                                                                min={today}
                                                                max={endDate}  
                                                            />
                                                            <p id='dateError' role="alert" style={{margin:'0'}}></p>
                                                        </div>
                                                        {formatDateandWeekend(this)}
                                                        </Grid>                                          
                                                        <Grid id='timeGrid' item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <div id='timePicker' className="uopx-wrapper uopx-wrapper--default">
                                                        <label htmlFor="UOPtime">Schedule contact time *</label>
                                                            <TimePicker
                                                                id="UOPTime"
                                                                name="UOPTime"
                                                                aria-label="Schedule contact time"
                                                                data={apptsAvailable}
                                                                passedTime={e => this.onTimePicked(e,props)}                                                                                                                              
                                                                //onChange={e => this.onTimePicked(e,props)}
                                                            ></TimePicker>
                                                            <p id='timeError' role="alert"></p>
                                                        </div>
                                                        {/* {addRole()} */}
                                                        </Grid>
                                                        </fieldset> 
                                                        </Grid>
                                                                                                    
                                                 
                                                </>}
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}
                                                </div>
                                                <Button name='submit' size='large' type='submit' className="btn-submit">
                                                            Request information
                                                        </Button>
                                                </Grid>
												<FormikErrorFocus offset={-50} align={'top'} focusDelay={200} ease={'linear'} duration={1000} />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}>
                                                </Grid>
                        <Grid item md={12} sm={12} lg={3} xs={12} className="maxWidthContainer-twoGridoneStepForm">
                        <h2 className="oneForm-four-grid-step" role="heading" aria-level="2"><span className="oneStepSolidBlock">Why</span> choose us</h2>
                        <div className="oneForm-Rectangleline">
                            <ul >
                                <li className="oneStepForm-li" ><strong>No application fee, SAT, GMAT or essay requirements.</strong> We'll request your prior college transcripts for you, at no cost, when possible.</li>
                                <li className="oneStepForm-li">If you are a qualifying new student, you’ll be <strong>awarded one of our scholarships or the best savings</strong>  you qualify for.</li>
                                <li className="oneStepForm-li">Earn your degree faster, and for less. Eligible transfer credits could cover up to <strong>70% of your bachelor's degree</strong>.</li>
                                <li className="oneStepForm-li">Interactions with our academic counselors earned a <strong>5-star rating from 85% of our surveyed students*</strong>.</li>
                            {/*    <li className="oneStepForm-li">More than <strong>100 online programs</strong> aligned to 300+ occupations.</li> */}                                
                            </ul>
                            <ul style={{listStyleType: "none"}}>
                                <li>
                                    <strong>*</strong> Transactional Survey; September 2022 – August 2023 (23,739 respondents)
                                </li>
                            </ul>
                            <img src={skillsblade} alt="Earn career-relevant skills in weeks - not years" style={{ width: "100%", marginTop:"24px" }}></img>
                            
						</div>
                        </Grid>
                          </Grid>
                    </Grid>
                </main>
                <Footer /> 
            </div>
        );
    }
    isAScheduledCall(values) {
        const {degreeLevel, fieldOfStudy, isScheduledCall} = values;
        if((fieldOfStudy === 'technology' || degreeLevel === 'masters') && this.state.isScheduledCallRadio === true){
            return true;
        }
    }
}

RFIDatePicker.defaultProps = {
    classes: {},
};

RFIDatePicker.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(RFIDatePicker);

const validateDate = (value, time) => {    
    //console.log('values passed into validateDate ', value, time );    
    let parsedDateObject = '';
    if (value === null) {        
        const todaysDate = moment().format("YYYY-MM-DD");
        const dateObject = new Date(`${todaysDate}T${time}`);
        parsedDateObject = Date.parse(dateObject);
        dateHasBeenChosen=false;
    //    console.log("parsedDateObject value null ", parsedDateObject);
    } else {
        const passedDateValue = moment(value).format("YYYY-MM-DD");
        const dateObject = new Date(`${passedDateValue}T${time}`);
        parsedDateObject = Date.parse(dateObject);
        dateHasBeenChosen=true;
    //    console.log("parsedDateObject value NOT null ", parsedDateObject);
    }
    let isValidTime = false;
    const date = new Date(value); // Fri Apr 22 2022 00:00:00 GMT-0700 (Mountain Standard Time)
    const timeInThirty = new Date().setMinutes(new Date().getMinutes() +30);
    if (timeInThirty<parsedDateObject){
        isValidTime = true
    }
    const dateString = new Date(value).toLocaleDateString('en-US',{
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }); // 4/22/2022
    const timeInThirtyFormatted = moment(timeInThirty).format('h:mm A'); // 3:45 PM
    //console.log('isDateValid is ', isDateValid); // True / False
    if(dateHasBeenChosen===true){
        // Passes in selected date in this format: Sat Apr 02 2022 00:00:00 GMT-0700 (Mountain Standard Time) 
    // If no value then run this code and return Date is required
    // Date validation
         if (date === null || date === undefined || date === '') { // date is undefined, null or empty string
            console.log('Date is required')
            document.getElementById(dateErrorID).innerHTML = 'Date is required';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            document.getElementById(dateErrorID).setAttribute('aria-invalid', true);
            document.getElementById(dateErrorID).setAttribute('aria-errormessage', 'Date is required');            
            hasErrorMsg = true;
        }
        else if (isDateValid===true && Date.parse(dateString) < Date.parse(new Date().toLocaleDateString('en-US'))){ // if parsed date is earlier than current date
            console.log('Date must be in the future', date, new Date());
            document.getElementById(dateErrorID).innerHTML = 'Date must be in the future';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
            //return false;
        }
        else if (isDateValid===true && !isWeekend(date) && moment(date).format("YYYY-MM-DD")==="2023-12-25") { // if date is christmas
            document.getElementById(dateErrorID).innerHTML = 'Company holiday';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
        }
        else if (isDateValid===true && isWeekend(date)) { // if date is a sunday
            console.log(moment(date).format("YYYY-MM-DD"));
            if(moment(date).format("YYYY-MM-DD")==="2023-12-25"){
                document.getElementById(dateErrorID).innerHTML = 'Company holiday';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            } else {
                console.log('Please select a day other than Sunday');
                document.getElementById(dateErrorID).innerHTML = 'Please select a day other than Sunday';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            }
            //return false;
        }
        else if (isDateValid===true && (Date.parse(date)>=Date.parse(endDate) && moment(date).format("LLL") !== moment(endDate).format("LLL"))) { // if parsed date is further ahead than 5 business days
            console.log("Please select a date within the next 5 business days");
            document.getElementById(dateErrorID).innerHTML = 'Please select a date within the next 5 business days';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
        }
        else if ((isDateValid === true) && (date !== null) && (Date.parse(dateString) >= Date.parse(new Date().toLocaleDateString('en-US'))) && (isWeekend(date) === false)) { // if everything is good
            console.log("In else condition, everything all good");
        //    console.log(timeValue, timeInThirtyFormatted);
            if(document.getElementById(dateErrorID).innerHTML.length>0){
                document.getElementById(dateErrorID).innerHTML = '';
            }
            if(document.getElementById(dateErrorID).classList.contains('MuiFormHelperText-root') && document.getElementById(dateErrorID).classList.contains('Mui-error')) {
                document.getElementById(dateErrorID).classList.remove('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.remove('Mui-error');
            }
            if(document.getElementById('datePicker').classList.contains('uopx-wrapper--error')){
                document.getElementById('datePicker').classList.remove('uopx-wrapper--error');
            }
            hasErrorMsg = false;
            formRef.current.values.date=date;            
        }
        else { // catch all
            console.log("Date is invalid");
            document.getElementById(dateErrorID).innerHTML = 'Date is invalid';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
            //return false;
        }
    }
    // Time validation
    if(isDateValid === true && dateString === moment().format('L') && isValidTime === false) {
    //    console.log(isDateValid, dateString===moment().format('L'), isValidTime);
        document.getElementById(timeErrorID).innerHTML = `Time must be scheduled at least 30 minutes past current time`;
        document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
        document.getElementById(timeErrorID).classList.add('Mui-error');
        document.getElementById('timePicker').classList.add('uopx-wrapper--error');
        hasErrorTimeMsg = true;
    } else {
        if(document.getElementById(timeErrorID).innerHTML.length>0){
            document.getElementById(timeErrorID).innerHTML = '';
        }
        if(document.getElementById(timeErrorID).classList.contains('MuiFormHelperText-root') && document.getElementById(timeErrorID).classList.contains('Mui-error')) {
            document.getElementById(timeErrorID).classList.remove('MuiFormHelperText-root');
            document.getElementById(timeErrorID).classList.remove('Mui-error');
        }
        if(document.getElementById('timePicker').classList.contains('uopx-wrapper--error')){
            document.getElementById('timePicker').classList.remove('uopx-wrapper--error');
        }
        hasErrorTimeMsg = false;        
    }
}

function isWeekend(date) {
    let today = moment().format("YYYY-MM-DD");
    if(today === (moment().dayOfYear(359).format("YYYY-MM-DD"))){
        return date.getDay() === 0 || date.getDay() === 6
    } else {
        return date.getDay() === 0
    }
}
function isValidDate(date) {
    let timestamp = Date.parse(date);
    if (isNaN(timestamp) == false && timestamp > 0) {
        let d = new Date(timestamp);
        isDateValid = true;
    } else {
        isDateValid = false;
    }
}
function formatDateInput(input){
    let inputValue = input.replace(/\D/g, '');
    let inputValueArr = inputValue.split('');
    if (inputValueArr.length > 2 && inputValueArr.length <=4){ // Input looks like 1020
        inputValueArr.splice(2,0,'/');
    } else if (inputValueArr.length > 4) { // Input looks like 10202
        inputValueArr.splice(2,0,'/');
        inputValueArr.splice(5,0,'/');
    }
    inputValue = inputValueArr.join('');
    let checkValueArr = inputValue.split('/');
    if (checkValueArr[0]) checkValueArr[0] = checkValue(checkValueArr[0], 12);
    if (checkValueArr[1]) checkValueArr[1] = checkValue(checkValueArr[1], 31);
    inputValue = checkValueArr.join('/');
    inputValue = inputValue.substring(0,10);
    /* if(inputValue.length===1){
        phArr.splice(0,1,inputValue.charAt(0));
    } else if(inputValue.length===2){
        phArr.splice(1,1,inputValue.charAt(1));
    } else if(inputValue.length===3){
        phArr.splice(2,1,inputValue.charAt(2));
    } else if(inputValue.length===4){
        phArr.splice(3,1,inputValue.charAt(3));
    } else if(inputValue.length===5){
        phArr.splice(4,1,inputValue.charAt(4));
    } else if(inputValue.length===6){
        phArr.splice(5,1,inputValue.charAt(5));
    } else if(inputValue.length===7){
        phArr.splice(6,1,inputValue.charAt(6));
    } else if(inputValue.length===8){
        phArr.splice(7,1,inputValue.charAt(7));
    } else if (inputValue.length >= 9) {
        phArr.slice(0,8);
    } */
    /* newPhArr.splice(0,1,(phArr[0]+phArr[1]));
    newPhArr.splice(1,1,(phArr[2]+phArr[3]));
    newPhArr.splice(2,1,(phArr[4]+phArr[5]+phArr[6]+phArr[7]));
    if (newPhArr[0]) newPhArr[0] = checkValue(newPhArr[0], 12);
    if (newPhArr[1]) newPhArr[1] = checkValue(newPhArr[1], 31); */

    //input = newPhArr.join('/');
    input = inputValue;
    return input;
}

function checkValue(str, max) { // ex (13,12)
    if (str.includes('m')|| str.includes('d')|| str.includes('y')){
        return str;
    }
    
    if (str.charAt(0) !== '0' || str == '00') {
      let num = parseInt(str); // parses '13' > 13
      if (isNaN(num) || num <= 0 || num > max) num = '01'; // isNaN(13):false || num<= 0 :false || num > max : true -> num = 1;
        if(num == '01'){
            str=num 
        } else {
            str=num.toString();
        } 
    };
    return str;
};

function formatDateandWeekend (instance){    
    clearTimeout(instance.duetTimeout);
    instance.duetTimeout = setTimeout(function(){
        const picker = document.querySelector("duet-date-picker");  
    //    picker.isDateDisabled = isWeekend;              
        let scheduleYourCallHeader = document.getElementsByClassName('duet-date__mobile-heading');
        scheduleYourCallHeader.ariaLabel = 'Scheduled your call in the next few days';
        scheduleYourCallHeader.tabindex=0;
        let dateOption = document.getElementsByClassName('duet-date__day is-month');
        dateOption.tabIndex = 0;
        let closeButton = document.getElementsByClassName('duet-date__close');
        closeButton.ariaLabel = 'Close button';
        document.querySelector("#datePicker input").ariaRequired='true';
        document.querySelector("#datePicker input").ariaLabel='schedule contact date';
        /* picker.addEventListener("duetFocus", function(e) {
            document.querySelector("#datePicker label").innerHTML='Schedule contact date * (mm/dd/yyyy)';
        }); */
        picker.addEventListener("duetBlur", function(e) { // Fires once the input has been unfocused
            document.querySelector("#datePicker label").innerHTML=scheduledContactDateLabel;
            let input = document.getElementById('date').value;
            if(input !== ''){
                dateValue = input;                
            }
            isValidDate(dateValue);
            validateDate(dateValue,timeValue);
        });
       /*  picker.addEventListener("duetFocus", function(e) { // Fires once input is focused
            let input = document.querySelector('.duet-date__input');
            if (input.value.length === 0){
                input.value = "mm/dd/yyyy"
            }
            
        }); */
        picker.addEventListener("duetChange", function(e) { // Fires once a date has been chosen
            dateValue = e.detail.valueAsDate;
            isValidDate(dateValue);
            validateDate(dateValue,timeValue);
        });        
       /*  picker.addEventListener('keydown', function (e) {
            if (e.key === 'Backspace' || e.key === 'Delete') {
                let input = document.querySelector('.duet-date__input');                
                input.value = formatDateInput(input.value);
                let inputValue = input.value.replace(/\//g,'');
                inputValue = inputValue.split('');            
                if(Number.isInteger(parseInt(inputValue[7]))===true){
                    inputValue.splice(7,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[6]))===true && inputValue[7] === 'y'){
                    inputValue.splice(6,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[5]))===true && inputValue[6] === 'y'){
                    inputValue.splice(5,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[4]))===true && inputValue[5] === 'y'){
                    inputValue.splice(4,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[3]))===true && inputValue[4] === 'y'){
                    inputValue.splice(3,1,'d');
                } else if(Number.isInteger(parseInt(inputValue[2]))===true && inputValue[3] === 'd'){
                    inputValue.splice(2,1,'d');
                } else if(Number.isInteger(parseInt(inputValue[1]))===true && inputValue[2] === 'd'){
                    inputValue.splice(1,1,'m');                
                } else if(Number.isInteger(parseInt(inputValue[0]))===true && inputValue[1] === 'm'){
                    inputValue.splice(0,1,'m');                    
                }                
                phArr=inputValue;                
                input.value=phArr.join('')                
            }
        }); */
        picker.addEventListener('input', (e) => {
            let input = document.querySelector('.duet-date__input');
            let newPh = [];
            if(input.value.length > 7) {
                let ph  = input.value.split('/');   
                for(let i = 0; i<3; i++){
                    if (ph[i].length === 1 && ph[i] != "0" && i<2 ) {
                        ph[i]=ph[i].padStart(2,'0');                    
                    }
                    newPh.push(ph[i])
                }                             
            }
            if(newPh.length === 0){
                input.value = formatDateInput(input.value);
            } else {
                input.value = formatDateInput(newPh.join('/'));
            }
            
        });
    /*    const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
        picker.dateAdapter = {
            parse(value = "", createDate) {
                const matches = value.match(DATE_FORMAT_US)
                if (matches) {
                return createDate(matches[3], matches[1], matches[2])
                }
            },
            format(date) {
                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            },
        }
        picker.localization = {
            buttonLabel: "Choose date",
            placeholder: "mm/dd/yyyy",
            selectedDateMessage: "Selected date is",
            prevMonthLabel: "Previous month",
            nextMonthLabel: "Next month",
            monthSelectLabel: "Month",
            yearSelectLabel: "Year",
            closeLabel: "Close window",
            calendarHeading: "Schedule your call in the next few days",
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            locale: "en-US",
          } */
    }, 1000);   
}