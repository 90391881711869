import { profanityCheck } from '../CommonComponents';

export function validate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name requires a value';
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
    errors.firstName = 'First Name appears invalid';
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = 'First name appears invalid';
    }
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name requires a value';
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
    errors.lastName = 'Last Name appears invalid';
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = 'Last name appears invalid';
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = 'Email Address requires a value';
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = 'Email address appears invalid';
  }
  if (!values.flex_more_info__c) {
    errors.flex_more_info__c = 'What can we help you with requires a value';
  }
  return errors;
}

export function emailSubscribe(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name requires a value';
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
    errors.firstName = 'First Name appears invalid';
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = 'First name appears invalid';
    }
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name requires a value';
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
    errors.lastName = 'Last Name appears invalid';
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = 'Last name appears invalid';
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = 'Email Address requires a value';
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = 'Email address appears invalid';
  }
  return errors;
}

export function contactValidate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name requires a value';
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
    errors.firstName = 'First Name appears invalid';
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = 'First name appears invalid';
    }
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name requires a value';
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
    errors.lastName = 'Last Name appears invalid';
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = 'Last name appears invalid';
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = 'Email Address requires a value';
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = 'Email address appears invalid';
  }
  if (!/^[\\0-9]*$/i.test(values.irn)) {
    errors.irn = 'IRN must be numeric';
  }
  if (!values.flex_more_info__c) {
    errors.flex_more_info__c = 'Comments / Questions requires a value';
  }
  return errors;
}

export function alumnicontactValidate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name requires a value';
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
    errors.firstName = 'First Name appears invalid';
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = 'First name appears invalid';
    }
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name requires a value';
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
    errors.lastName = 'Last Name appears invalid';
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = 'Last name appears invalid';
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = 'Email Address requires a value';
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = 'Email address appears invalid';
  }
  if (!values.flex_more_info__c) {
    errors.flex_more_info__c = 'Tell us what you need help with requires a value';
  }
  return errors;
}

export function alumniStoryValidate(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First Name requires a value';
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
    errors.firstName = 'First Name appears invalid';
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = 'First name appears invalid';
    }
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name requires a value';
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
    errors.lastName = 'Last Name appears invalid';
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = 'Last name appears invalid';
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = 'Email Address requires a value';
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = 'Email address appears invalid';
  }
  if (values.phoneNumber && values.phoneNumber.length !== 10) {
    errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
  }
  if (!values.graduationYear) {
    errors.graduationYear = 'Graduation year requires a value';
  }
  if (!values.flex_more_info__c) {
    errors.flex_more_info__c = 'Tell us about becoming a Phoenix';
  }
  if (!values.degreeLevel) {
    errors.degreeLevel = 'Degree Level requires a value';
  }
  return errors;
}

export function alumniBusinessFormValidate(values) {
  const errors = {};
  if (!values.firstAndLastName) {
    errors.firstAndLastName = 'First and Last Name requires a value';
  }
  if (values.firstAndLastName && !values.firstAndLastName.match(/^[A-Za-z '-]*$/)) {
    errors.firstAndLastName = 'First and Last Name appears invalid';
  }
  if (values.firstAndLastName.trim().indexOf(' ') === -1) {
      errors.firstAndLastName = 'Please enter a First and Last name';
  }
  if (values.firstAndLastName) {
    const check = profanityCheck(values.firstAndLastName);
    if (check === false) {
      errors.firstAndLastName = 'First and Last name appears invalid';
    }
  }
  if (!values.companyEmailAddress) {
    errors.companyEmailAddress = 'Email Address requires a value';
  }
  if (values.companyEmailAddress && !values.companyEmailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.companyEmailAddress = 'Email address appears invalid';
  }
  if (values.companyPhoneNumber && values.companyPhoneNumber.length !== 10) {
    errors.companyPhoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
  }
  if (!values.companyWebsite) {
    errors.companyWebsite = 'Company website requires a value';
  }
  if (values.companyWebsite && !values.companyWebsite.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
    errors.companyWebsite = 'Company website appears invalid';
  }
  if (!values.callToActionURL) {
    errors.callToActionURL = 'Call to actionURL requires a value';
  }
  if (values.callToActionURL && !values.callToActionURL.trim().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
    errors.callToActionURL = 'Call to actionURL appears invalid';
  }
  if (!values.callToAction) {
    errors.callToAction = 'Call to action requires a value';
  }
  if (!values.flex_more_info_business) {
    errors.flex_more_info_business = 'Description of business requires a value';
  }
  if (!values.flex_more_info_instructions) {
    errors.flex_more_info_instructions = 'Offer details and instructions of business requires a value';
  }
  if (!values.businessCategory) {
    errors.businessCategory = 'Business category requires a value';
  }
  return errors;
}


export function referFriendValidate(values) {
  const errors = {};
  if (!values.persona) {
    errors.persona = 'Persona field requires a value';
  }

  if (!values.fullName) {
    errors.fullName = 'First and Last name requires a value';
  } else if (values.fullName.trim().indexOf(' ') === -1) {
    errors.fullName = 'Please enter a First and Last name';
  }
  if (values.fullName) {
    const check = profanityCheck(values.fullName);
    if (check === false) {
      errors.fullName = 'Name appears invalid';
    }
  }
  /*    if (!values.firstName) {
      errors.firstName = "First Name requires a value";
    }
    if (values.firstName && !values.firstName.match(/^[A-Za-z ]*$/)) {
      errors.firstName = "First Name appears invalid";
    }
    if (!values.lastName) {
      errors.lastName = "Last Name requires a value";
    }
    if (values.lastName && !values.lastName.match(/^[A-Za-z ]*$/)) {
      errors.lastName = "Last Name appears invalid";
    }
    if (values.lastName) {
      let check = profanityCheck(values.lastName);
        if (check === false){
            errors.lastName = "Last name appears invalid";
        }
    }
    */
  if (!values.emailAddress) {
    errors.emailAddress = 'Email Address requires a value';
  }
  if (values.emailAddress && !values.emailAddress.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = 'Email address appears invalid';
  }

  if (!values.flex_referrer_fullName) {
    errors.flex_referrer_fullName = 'First and Last name requires a value';
  } else if (values.flex_referrer_fullName.trim().indexOf(' ') === -1) {
    errors.flex_referrer_fullName = 'Please enter a First and Last name';
  }
  if (values.flex_referrer_fullName) {
    const check = profanityCheck(values.flex_referrer_fullName);
    if (check === false) {
      errors.flex_referrer_fullName = 'Name appears invalid';
    }
  }
  // eslint-disable-next-line max-len
  // if (!values.flex_referrer_firstName) {
  //    errors.flex_referrer_firstName = "First Name requires a value";
  // }
  // eslint-disable-next-line max-len
  // if (values.flex_referrer_firstName && !values.flex_referrer_firstName.match(/^[A-Za-z '-]*$/)) {
  //   errors.flex_referrer_firstName = "First Name appears invalid";
  // }
  // if (values.flex_referrer_firstName) {
  //   let check = profanityCheck(values.flex_referrer_firstName);
  //     if (check === false){
  //         errors.flex_referrer_firstName = "First name appears invalid";
  //     }
  // }
  // if (!values.flex_referrer_lastName) {
  //   errors.flex_referrer_lastName = "Last Name requires a value";
  // }
  // eslint-disable-next-line max-len
  // if (values.flex_referrer_lastName && !values.flex_referrer_lastName.match(/^[A-Za-z '-]*$/)) {
  //   errors.flex_referrer_lastName = "Last Name appears invalid";
  // }
  // if (values.flex_referrer_lastName) {
  //   let check = profanityCheck(values.flex_referrer_lastName);
  //     if (check === false){
  //         errors.flex_referrer_lastName = "Last name appears invalid";
  //     }
  // }

  if (!values.flex_referrer_emailAddress) {
    errors.flex_referrer_emailAddress = 'Email Address requires a value';
  }
  if (values.flex_referrer_emailAddress && !values.flex_referrer_emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.flex_referrer_emailAddress = 'Email address appears invalid';
  }
  return errors;
}

  export function employerReferralValidate(values){
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'First Name requires a value';
    }
    if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
      errors.firstName = 'First Name appears invalid';
    }
    if (values.firstName) {
      const check = profanityCheck(values.firstName);
      if (check === false) {
        errors.firstName = 'First name appears invalid';
      }
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name requires a value';
    }
    if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
      errors.lastName = 'Last Name appears invalid';
    }
    if (values.lastName) {
      const check = profanityCheck(values.lastName);
      if (check === false) {
        errors.lastName = 'Last name appears invalid';
      }
    }
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    }
    if (values.phoneNumber && values.phoneNumber.length !== 10) {
      errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
    }
    if (values.isMilitary === '' || values.isMilitary === undefined) {
      errors.isMilitary = 'U.S. Military affiliation requires a value';
    }
  
  return errors;
  }


