import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
//import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
//import { Phoenix76Validate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, roleChange, pageNameByPath, fireAnalyticsEvent, fireNonRFISubmitEvent, profanityCheck, srSpeak} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import axios from 'axios';
//import checkmark from '../layout/images/checkmark.svg';
import Button from '../layout/MaterialComponents/button/Button';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
import { radioOption } from '../common/constants';
//import { initValues } from '../common/configTools';

const initialPhoenix76Values = {
    yourfirstName: '',
    yourlastName: '',
    yourfullName: '',
    youremailAddress: '',
    //yourphoneNumber:'',
    relationshipWithAlumni:'',
    nomineefirstName: '',
    nomineelastName: '',
    nomineefullName: '',
    nomineeemailAddress: '',
    //nomineephoneNumber:'',
    nomineeCompanyName:'',
    nomineeLinkedInURL:'',
    accomplishments:'',
    isNominatingYourself:'',
    moreInfo: '',
    degreeLevel:'',
    requestingUrl: '',
    disclaimer:'By submitting, you consent to University of Phoenix contacting you regarding our educational programs and services using email, telephone or text — including our use of automated technology for calls, pre-recorded messages and periodic texts to any wireless number you provide. Message and data rates may apply. This consent is not required to purchase goods or services and you may always call us directly at <a target="_blank" href="tel:8007952586">1-800-795-2586</a>.',
    formName: 'Phoenix76',
};

const formRef = {};
let prevDegreeLevel;
//let defaultTextHTML = "<h1>Email us</h1>";
const disclaimerText = initialPhoenix76Values.disclaimer;
const isMobileView = window.innerWidth <= 640;

let isSelf;
let secondaryTitle, secondaryDesc;

let headerDesktop = '<h2 id="dialogTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 28px; font-weight: 700; line-height: 40px; display:flex; align-items:left; justify-content: left; margin-top:0;">Nominate a Phoenix 76 Hall of Famer.</h2>';
let descriptionDesktop = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">Do you know a University of Phoenix graduate who should be honored? Before you tell us their story, tell us a bit about you.</div>';
let headerMobile = '<h2 id="dialogTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 21px; font-weight: 700; line-height: 28px; display:flex; align-items:left; justify-content: left; margin-top:0;">Nominate a Phoenix 76 Hall of Famer.</h2>';
let descriptionMobile = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">Do you know a University of Phoenix graduate who should be honored? Before you tell us their story, tell us a bit about you.</div>';
let thankyouMessageTitleMobile = '<h2 id="thanksdialogtitle" class="mainHeader" style="font-size: 21px; font-weight: 700; line-height: 24px; display:flex; align-items:center; justify-content: left; text-align:center; margin-top:0;">Thank you!</h2>';
let thankyouMessageTitleDesktop = '<h2 id="thanksdialogtitle" class="mainHeader" style="font-size: 27px; font-weight: 700; line-height: 32px; display:flex; align-items:center; justify-content: left; text-align:center; margin-top:0;">Thank you!</h2>';
let thankyouMessageDescriptionMobile = `<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="font-size: 16px; line-height: 21px; display:flex; align-items:center; justify-content: left; margin-top:0;">You're helping to make our alumni community stronger.</div>`;
let thankyouMessageDescriptionDesktop = `<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="font-size: 18px; line-height: 22px; display:flex; align-items:center; justify-content: left; margin-top:0;">You're helping to make our alumni community stronger.</div>`;

function generateYearsBetween(startYear = 1976, endYear) {
    let endDate = endYear || new Date().getFullYear();
    let years = [];
    for (var i = endDate; i >= startYear; i--) {
      years.push(endDate);
      endDate--;
    }
    return years;
}

const yearsArray = generateYearsBetween();
const yearOpts = yearsArray.map((year) => {
    return ( { label: year, value: year} );
});

const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
}

const degreeLevel = [
    { label: "Associate", value: "Associate" },
    { label: "Bachelors", value: "Bachelors" },
    { label: "Masters", value: "Masters" },
    { label: "Doctorate", value: "Doctorate" },
]; 

class Phoenix76 extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            isNominatingYourselfRadio:'',
            initialValues: initialPhoenix76Values,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
    //    document.title = `Re-entry contact-us`;
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href;
        /*prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : 'Associate';
        if (prevDegreeLevel === 'Associate') {
            formRef.current.values.degreeLevel = prevDegreeLevel;
        } */
    //    initValues(initialValues, this.props);
        this.setState({ initialValues });
    //    getIPAddress(this.updateIPAddress);
        const { currentStep } = this.state;
        formStep = currentStep;  
	    formType = 'Non-RFI';
    	formName = 'Phoenix76';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }

    dropdownChange = (name, value) => {
        if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                prevDegreeLevel = value;
            }
        }
    }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
	        fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }

    conditionalRadio(values) {
        const {isNominatingYourself} = values;
        if(isNominatingYourself === 'Yes' || isNominatingYourself === 'No'){
            return true;
        }
    };

    isNominatingYourself(values) {
        if(this.state.isNominatingYourselfRadio){
            return true;
        }
    }
    
    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isNominatingYourself') {
            formRef.current.values.isNominatingYourself = value;
            if(value === "Yes"){ 
                isSelf = '';
                this.setState({isNominatingYourselfRadio: true});
                secondaryTitle = `Tell us why you're a great candidate`;
                secondaryDesc = `Share the accomplishments and qualities you think we should know about.`;
            }
            if(value === "No") {
                isSelf= "Nominee's ";
                this.setState({isNominatingYourselfRadio: false});
                secondaryTitle = `What makes your nominee exceptional? `;
                secondaryDesc = `Please tell us who you're nominating and why. Share the accomplishments and qualities you think we should know about.`;
            }

        }
    };

    handleSubmit = (values) => {
    //    hashValues(values);   
        //values.firstName = (values.firstName).trim();
        //values.lastName = (values.lastName).trim();
        this.setState({ initialValues: values });
        const headers = {'Content-Type': 'application/json'};
        let URL = 'https://www.phoenix.edu/request/sendmail';
        const data = JSON.stringify(values);
        axios.post(URL, data, {headers})
        .then((response) => {
            if (response.data.msg === 'success'){
                console.log("Email sent, awesome!");
                this.setState({
                    initialValues: values,
                    isSubmit: true,
                  });
            }else if(response.data.msg === 'fail'){
                console.log("Oops, something went wrong. Try again")
            }
        })
        .catch(error => {
            console.log (error.message);   
        });
	    fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
        srSpeak('your request has been submitted');
        window.setTimeout(function () {
            document.querySelector('#thanksdialogtitle').setAttribute('tabIndex', '-1');
            document.querySelector('#thanksdialogtitle').focus();      
        }, 300);
    }

    Phoenix76Validate (values) {
        const errors = {};
        if (values.isNominatingYourself === '' || values.isNominatingYourself === undefined) {
            errors.isMilitary = 'Are you nominating yourself requires a value';
        }
        if ((values.isNominatingYourself === 'Yes' || values.isNominatingYourself === 'No')) {
            if (!values.yourfullName) {
                errors.yourfullName = 'Your First and Last name requires a value';
            } else if (values.yourfullName.trim().indexOf(' ') === -1) {
                errors.yourfullName = 'Please enter a First and Last name';
            } else if (!profanityCheck(values.fullName)) {
                errors.yourfullName = 'Full Name appears invalid';
            }
            if (!values.youremailAddress) {
                errors.youremailAddress = 'Your Email Address requires a value';
            }
            if (values.youremailAddress && !values.youremailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                errors.youremailAddress = 'Your Email address appears invalid';
            }
            /*if (!values.yourphoneNumber) {
                errors.yourphoneNumber = 'Your Phone number requires a value';
            }
            if (values.yourphoneNumber && values.yourphoneNumber.length !== 10) {
                errors.yourphoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            } */
        }
        if (values.isNominatingYourself === 'Yes') {
            if (!values.accomplishments) {
                errors.accomplishments = 'Why are you a Phoenix 76 Hall of Famer requires a value';
            }
        } 
        if (values.isNominatingYourself === 'No') {
            if (!values.nomineefullName) {
                errors.nomineefullName = `Nominee's First and Last name requires a value`;
            } else if (values.nomineefullName.trim().indexOf(' ') === -1) {
                errors.nomineefullName = `Please enter Nominee's First and Last name`;
            } else if (!profanityCheck(values.nomineefullName)) {
                errors.nomineefullName = `Nominee's Full Name appears invalid`;
            }
            if (!values.nomineeemailAddress) {
              errors.nomineeemailAddress = `Nominee's Email Address requires a value`;
            }
            if (values.nomineeemailAddress && !values.nomineeemailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              errors.nomineeemailAddress = `Nominee's Email address appears invalid`;
            }
            /*if (!values.nomineephoneNumber) {
                errors.nomineephoneNumber = `Nominee's Phone number requires a value`;
            }
            if (values.nomineephoneNumber && values.nomineephoneNumber.length !== 10) {
                errors.nomineephoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            }*/
            if (!values.relationshipWithAlumni) {
                errors.relationshipWithAlumni = 'Your relationship with alumni requires a value';
            }
            if (!values.accomplishments) {
                errors.accomplishments = 'Why are they a Phoenix 76 Hall of Famer requires a value';
            }
        } 
        
        //console.log('errors', errors, values);
        return errors;
    }

    
    render() {
    //    const { classes } = this.props;
        console.log ( secondaryTitle, secondaryDesc);
        let secondaryheaderDesktop = `<h3 id="secondaryTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 21px; font-weight: 700; line-height: 40px; display:flex; align-items:left; justify-content: left; margin-top:0;">${secondaryTitle}</h3>`;
        let secondarydescriptionDesktop = `<div id="secondaryDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">${secondaryDesc}</div>`;
        let secondaryheaderMobile = `<h3 id="secondaryTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 18px; font-weight: 700; line-height: 28px; display:flex; align-items:left; justify-content: left; margin-top:0;">${secondaryTitle}</h3>`;
        let secondarydescriptionMobile = `<div id="secondaryDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">${secondaryDesc}</div>`;
        
        const { isSubmit, initialValues } = this.state;
        let titleText = isMobileView ? (this.props.title || headerMobile) : (this.props.title || headerDesktop);
        let descriptionText = isMobileView ? (this.props.desc || descriptionMobile) : (this.props.desc || descriptionDesktop);
        let thankyouMessageTitle = isMobileView ?  (this.props.thankyoumessagetitle || thankyouMessageTitleMobile) : (this.props.thankyoumessagetitle || thankyouMessageTitleDesktop);
        let thankyouMessageDescription = isMobileView ? (this.props.thankyoumessagedescription || thankyouMessageDescriptionMobile) : (this.props.thankyoumessagedescription || thankyouMessageDescriptionDesktop);
        let secondarytitleText = isMobileView ? (this.props.secondarytitle || secondaryheaderMobile) : (this.props.secondarytitle || secondaryheaderDesktop);
        let secondarydescriptionText = isMobileView ? (this.props.secondarydesc || secondarydescriptionMobile) : (this.props.secondarydesc || secondarydescriptionDesktop);
        
        return (
            
            <div>
            {/*   <Header />   */ }
                <div className="Phoenix76-main">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid item md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">

                     {!isSubmit
                    ? (
                        <>
                        <Grid item md={12} sm={12} lg={12} xs={12}>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.Phoenix76Validate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  //aria-label="Phoenix 76"
                                                  aria-labelledby={isSubmit? "thanksdialogtitle thanksdialogdesc" : "dialogTitle dialogDesc"}
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                                { unescapeHTML(titleText)}
                                                { unescapeHTML(descriptionText)}
                                                <div className="reqinfo" > * Required information </div>
                                            <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm" style={{ maxWidth: '100%'}}>
                                                <MaterialRadio className="selectRadioLabel" value={formRef?.current?.values.isNominatingYourself} row onChange={this.handleChange} items={radioOption} label="Are you nominating yourself? *" name="isNominatingYourself" ariaLabel="Are you nominating yourself? *" />
                                            </Grid>
                                            {this.conditionalRadio(props.values) && <>        
                                            <Grid container >
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput
                                                            name="yourfullName"
                                                            id="yourfullName"
                                                            label="Your First and Last name *"
                                                            ariaRequired="true"
                                                            ariaLabel="Your First and Last name"
                                                            variant="outlined"
                                                            className='uopx-input modalfield'
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                        />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="youremailAddress" id="youremailAddress" label="Your Email address *" ariaRequired="true" ariaLabel="Enter your Email address" variant="outlined" />
                                                    </Grid>
                                            {/*        <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                            <MaterialPhoneNumber name="yourphoneNumber" id="yourphoneNumber" label="Your Phone number *" ariaLabel="Enter your phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                    </Grid>  */}
                                                    { !this.isNominatingYourself(props.values) &&
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="relationshipWithAlumni" id="relationshipWithAlumni"
                                                            label="Your relationship to alumni *" ariaRequired="true" ariaLabel="Relationship With Alumni" onClick={this.handleAnalyticsRFIFormStart} />
                                                    </Grid>
                                                    }
                                                </Grid>
                                                <Grid container>
                                                    {this.isNominatingYourself(props.values) &&
                                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                                        <hr className="separator"></hr>
                                                        { unescapeHTML(secondarytitleText)}
                                                        { unescapeHTML(secondarydescriptionText)}
                                                    </Grid>
                                                    }
                                                    { !this.isNominatingYourself(props.values) && <>
                                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                                        <hr className="separator"></hr>
                                                        { unescapeHTML(secondarytitleText)}
                                                        { unescapeHTML(secondarydescriptionText)}
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput
                                                            name="nomineefullName"
                                                            id="nomineefullName"
                                                            label="Nominee's First and Last name *"
                                                            ariaRequired="true"
                                                            ariaLabel="Enter nominee's First and Last name"
                                                            variant="outlined"
                                                            className='uopx-input'
                                                        />
                                                    </Grid> 
                                                    
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="nomineeemailAddress" id="nomineeemailAddress" label="Nominee's Email address *" ariaRequired="true" ariaLabel="Enter nominee's Email address" variant="outlined" />
                                                    </Grid> 
                                                    
                                            {/*        <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                            <MaterialPhoneNumber name="nomineephoneNumber" id="nomineephoneNumber" label="Nominee's Phone number" ariaLabel="Enter nominee's phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                    </Grid>  */}
                                                    </>}
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <SelectField
                                                            id="degreeLevel"
                                                            name="degreeLevel"
                                                            label={isSelf + "Highest degree earned"}
                                                            data={degreeLevel}
                                                            className="MuiFormControl-fullWidth"
                                                            ariaRequired="true"
                                                            formRef={formRef}
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                            onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                        ></SelectField>
                                                        {roleChange()}
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="nomineeCompanyName" id="nomineeCompanyName"
                                                            label={isSelf + "Company Name"} ariaRequired="true" ariaLabel={"Enter "+ isSelf +"Company Name"} />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="nomineeLinkedInURL" id="nomineeLinkedInURL"
                                                            label={isSelf + "linkedIn URL"} ariaRequired="true" ariaLabel={"Enter "+ isSelf + "LinkedIn URL"} />
                                                    </Grid>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm" >
                                                    <MaterialTextArea
                                                        id="moreInfo"
                                                        name="moreInfo"
                                                        onChange={onTextAreaChange}
                                                        rows={2}
                                                        label={"If applicable, please include any links to the " + isSelf + "websites, blog articles..."}
                                                        classNames="uopx-input more_info_textarea"
                                                        ariaLabel={"If applicable, please include any links to the " + isSelf + "websites, blog articles..."}
                                                        ariaRequired="true"
                                                        //placeholder="Suggestions: What inspired you to go to college? What motivated you to keep going? What did you learn about yourself? Any advice for someone considering enrolling?"
                                                    />
                                                </Grid>     
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm" >
                                                    <MaterialTextArea
                                                        id="accomplishments"
                                                        name="accomplishments"
                                                        onChange={onTextAreaChange}
                                                        rows={2}
                                                        label="Why are you a Phoenix 76 Hall of Famer? *"
                                                        classNames="uopx-input more_info_textarea"
                                                        ariaLabel="Why are you a Phoenix 76 Hall of Famer?"
                                                        ariaRequired="true"
                                                        //placeholder="Suggestions: What inspired you to go to college? What motivated you to keep going? What did you learn about yourself? Any advice for someone considering enrolling?"
                                                    />
                                                </Grid>   

                                                <Grid item md={9} sm={12} lg={12} xs={12} className ={"margin-OneStepForm"} style={{ alignItems: 'center', display: 'flex' }}>
                                                    <div className="disclaimer">
                                                        { unescapeHTML(disclaimerText) }
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} sm={12} lg={12} xs={12} className ={"margin-OneStepForm submit-btn"}>
                                                    <Button size='large' type='submit' className="btn-submit"> Submit </Button>
                                                </Grid>
                                            </Grid>
                                            </>}
							                <ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        </>
                    )
                    : (
                          <>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                {unescapeHTML(thankyouMessageTitle)}
                                {unescapeHTML(thankyouMessageDescription)}
                            </Grid>
                          </>
                        )}
                          </Grid>
                    </Grid>
                </div>
            { /*   <Footer /> */ } 
            </div>
        );
    }
}

Phoenix76.defaultProps = {
    classes: {},
};

Phoenix76.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(Phoenix76);