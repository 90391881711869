import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; // prop-types
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import { addDays, format, isBefore } from 'date-fns';
import isToday from 'date-fns/isToday';
import date from 'date-and-time';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
import styles from '../layout/styles/MobileFormStyles';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { validate3Step, validateOneStep } from '../layout/MobileFormValidation';
import { unescapeHTML, processJSONParameter } from '../common/configTools';
import getIPAddress, { conditionalCBE, fireAnalyticsEvent, fireCampaignEvent, fireCampaignRadioEvent, fireFOS_DlEvent, getDegreeLevel, loadCookiesData, roleChange, submitForm, updatePrePopCookieValue, profanityCheck, scrollToTop, mapAdditionalFields, feedDataToSessionStorage } from '../CommonComponents';
import { apptsAvailable, courseCategoryDropdown, degreeLevel, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, radioOption } from '../common/constants';
import {UOPDatePicker} from '../layout/MaterialComponents/UOPDatePicker';
import TimePicker from '../layout/MaterialComponents/CustomTimePicker';
import UOPHeader from '../layout/UopHeader';
import Footer from '../layout/Footer';
import '../../../node_modules/@duetds/date-picker/dist/duet/themes/default.css';
import moment from 'moment';
import Button from '@mui/material/Button';
import UOPXButton from '../layout/MaterialComponents/button/Button';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import { ThreeSixtyTwoTone } from '@mui/icons-material';
import {dataLayer} from '../common/dataLayer';
// import skillsblade from '../layout/images/skillsblade.svg';

const imagePath = "https://phoenix.edu/content/dam/edu/tuition-financial-aid/img/illustration/general/transfer-pre-evaluation.png";
const formRef = {};
var feedback = {};
let prevFOS, prevDegreeLevel, prevState,prevCourseCategory;
let courseCategoryFlag = false;
const disclaimerText = rfiFormInitialValues.disclaimer;
let isSavingsExplorer = false;
let subHeader = "Answer a few questions and an enrollment representative will connect with you to chat about options that can help you save time and money on your degree.";
let dateHasBeenChosen = false;
let dateValue = null;
let timeValue = null;
let isDateValid = false;
let hasErrorMsg = false;
let hasErrorTimeMsg = false;
let today = new Date();
let endDate = today.getDay() === 1 ? addDays(today, 4) : addDays(today, 5);
let errors = {};
const timeErrorID = 'timeError';
const dateErrorID = 'dateError';
today = today.toISOString().split('T')[0];
endDate = endDate.toISOString().split('T')[0];
let scheduledContactDateLabel = "Schedule contact date * (mm/dd/yyyy)";

let h1Step1 = "We’re excited to connect with you";
let h1Step2 = "Tell us how to reach you";
let h1Step3 = "Tell us when to call you";
let subHeaderStep1 = "Share a few details to help us prepare for our conversation.";
let subHeaderStep2 = "Please share your contact information so we can call at the time you request.";
let subHeaderStep3 = "We’ll be ready to talk about your preliminary evaluation and answer questions about saving time and money and more.";
let thankYouPageHeader = "Thank you!";
let thankYouPageSubTitleASAP = "We look forward to our talk. Along with talking about your preliminary evaluation, we will be prepared to answer your questions about:"
let thankYouPageSubTitlePart1dateTime = "We look forward to connecting with you on ";
let thankYouPageSubTitlePart2dateTime = ". Along with talking about your preliminary evaluation, you can ask about: ";
let displayDate = null;
let displayTime = null;
let schedACallFlag = 'Yes'; // Required for radio button defaulting
let scrollOffSetStepper = -100;
let scrollOffSetErrors = -250;

const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};

const hourFormatter = value => {
    let [hours, minutes] = value.split(':');
    hours = parseInt(hours);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${hours}:${minutes} ${ampm}`;
}

const scheduleACallRadioOption = [
    { value: "Yes", label: "Schedule our conversation" },
    { value: "No", label: "Call me as soon as possible" },
  ];

class ScheduleACall extends Component {
    constructor(props) {
        super(props);        
        this.initialState = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            formRef: null,
            variant: props.variant,
            feedback: any,
	        activeStep: 1,
            totalSteps: 3,
            isScheduledCallRadio: false,
            fieldOfStudyOptions: processJSONParameter([...dynamicFieldOfStudyDropdownVariant]),
            degreeLevel: processJSONParameter([...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter([...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
        };
        this.state = this.initialState;
        this.ref = {
            field: {
                date: React.createRef(),
                time: React.createRef(),
            }
        };
        this.stepperRef = React.createRef();
    }

    componentDidMount() {
        window.pagePath = "page-dc_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Desktop`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "RFI schedule call desktop - 1 step";
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        if (document.referrer.indexOf('/cost-savings/ways-to-save/results')>0){
            isSavingsExplorer=true;
            subHeader="Answer a few questions and an enrollment representative will reach out soon.";
        }
        //initialValues.date= new Date();
        //initialValues.date=null;
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        this.handleZipcodeChange(initialValues.postalCode,initialValues.stateProvince);
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        const { activeStep } = this.state;
        formStep = activeStep;
        // fetch feedback
    /*    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }

        fetch(baseUrl + '/services/feedback/v1/summary',
            {
                method: 'GET',
            })
            .then(response => response.json())
            .then(data => feedback = data)
            .catch(function (error) {
                console.error(error);
            }); */
	    if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer();
        this.scrollToElement(this.stepperRef.current); // THIS WILL TRIGGER THE ANALYTCS EVENT THAT USER SCROLLED EVEN IF THEY DIDN'T
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
        }
        if (name === 'isScheduledCall') {
            schedACallFlag = value;
            formRef.current.values.isScheduledCall = value;
            if(value === "Yes"){ 
                this.setState({isScheduledCallRadio: true});
                formRef.current.values.flex_rfiCallback = true;
            }
            if(value === "No") {
                this.setState({isScheduledCallRadio: false});
                formRef.current.values.flex_rfiCallback = false;
            }
            if (this.state.not_abandoned !== true) {
                this.setState({ not_abandoned: true });
            }
            if (this.state.scheduledCallRadio !== true) {
                fireCampaignRadioEvent('rfi_schedule_call', value, formName, formType);
                this.setState({ scheduledCallRadio: true });
            }
        }
    };

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }
                else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
            }
        }
        else if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                initialValues.degreeLevel = value
                this.validate(initialValues);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }
    
    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
          var optionExists = newDegreeLevelOptions.some((e)=>this.state.initialValues.degreeLevel===e.value);
          //if prev degree level doesnt exist, reset
          if (!optionExists) formRef.current.setFieldValue('degreeLevel', '')
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);

            if (state && prevState !== state) {
                prevState = state;
            }
            this.updateAndNotify(state);
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    isCBEConsent = (event, checked) => {
        console.log(event); 
        console.log(checked)
        formRef.current.values.isCBE = checked;        
    }

    onDatePicked = (date, formProps) => {
    //  console.log ("onDatePicked date is ", date);
        let selectedDate = new Date(date);
        //formProps.values.date = selectedDate;
        let timeMin = new Date(selectedDate).getMinutes();
        let currentHour = new Date().getHours();
        let currentMin = new Date().getMinutes();
        if (selectedDate !== null){
            if (isToday(new Date(selectedDate))) {
                if (currentHour >= 17 || (currentHour >= 16 && currentMin > 30)){
                    selectedDate = addDays(new Date(), 1);
                    selectedDate = new Date(selectedDate).setHours(9,0);
                } else if (currentHour <= 8 && currentMin < 30) {
                    //selectedDate = addDays(new Date(), 1);
                    selectedDate = new Date(selectedDate).setHours(9,0);
                } else {
                    if (isBefore(new Date(selectedDate), new Date())) { selectedDate = new Date(new Date().setMinutes(timeMin+30));}
                }
            }
            formProps.setFieldValue("date", new Date(selectedDate).toLocaleDateString("en-US"));
            formProps.validateForm();
        }
    };

    onTimePicked = (time, formProps) => {
        formProps.setFieldValue("time", time);
        console.log("time", time);        
        timeValue=time;
        formRef.current.values.time=timeValue;
        validateDate(dateValue, timeValue);
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            initialValues: formRef.current.values
        });
        fireAnalyticsEvent('RFIStepBack', formName, formType);
        this.scrollToElement(this.stepperRef.current);
        this.stepperRef.current.focus({preventScroll: true});
    };

    handleSubmit = async (values) => {    
        const { activeStep, totalSteps } = this.state;
        this.setState({ initialValues: values });
        if (activeStep === totalSteps && document.activeElement.name !== "next") {
            if (((values.fullName).trim().indexOf(' ')) > 0) {
                const i = (values.fullName.trim()).indexOf(' ');
                values.firstName = (values.fullName.trim()).substring(0, i); 
                values.lastName = (values.fullName.trim()).substring(i + 1);
            }  
            values.flex_more_info__c = "Pre-Evaluation Scheduled Call ";
            values.flex_RFI_Callback__c = 'rfiCallback-true ';
            values.flex_rfiCallback = true; // CHECK ABOUT THIS FLAG LATER
            if (dateValue) {
                values.date = dateValue;
            }
            if (values.time) {
                let date = new Date(values.date).toLocaleDateString("en-US");
                let datetime = new Date(`${date} ${values.time}`).toLocaleString('en-US', { hour12: true });
                values.date = datetime;
                values.date = moment(values.date).format('YYYY-MM-DDTHH:mm:ss');
            }
            values.flex_rfiCallbackTimezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
            if (values.date && (schedACallFlag === 'Yes'|| values.isScheduledCall === true)) {
                let isoDate = new Date(values.date).toISOString().split('.')[0] + "Z";           
                values.flex_rfiCallbackDateTime = isoDate;                
                values.flex_RFI_Callback__c += isoDate;
                values.flex_more_info__c += "D/T";
            }
            if(schedACallFlag === 'No'|| values.isScheduledCall === false) {
                let timeInTen = new Date().setMinutes(new Date().getMinutes() + 10);
                timeInTen = new Date(timeInTen).toISOString().split('.')[0] + "Z";
                values.flex_rfiCallbackDateTime = timeInTen;                
                values.flex_RFI_Callback__c += timeInTen;
                values.flex_more_info__c += "ASAP";
            }

            values.isScheduledCall = 'Yes';
            // this.setState({ initialValues: values, isSubmit: true });

            // SUBMISSION LOGIC
            mapAdditionalFields(values);
            feedDataToSessionStorage(values);
            values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
            Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
            Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);
            let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
            if (window.location.host !== 'www.phoenix.edu') {
                postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
            }
            try {
                const response = await fetch(postURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(values),
                });
                fireAnalyticsEvent('RFISubmit', formName, formType);
                this.setState({
                    initialValues: values,
                    isSubmit: true,
                    message: null,
                });
                this.scrollToElement(document.getElementsByClassName('thankyoumessage')[0], scrollOffSetStepper);
            } catch (e) {
                this.setState({
                    message: <div className="">{e.message || e}</div>,
                });
            }
        }
        else if (document.activeElement.name === "next") {
            if (activeStep <= totalSteps - 1)
                this.setState({ activeStep: activeStep + 1 });
            fireAnalyticsEvent('RFIStep' + (activeStep + 1), formName, formType);
            formStep = activeStep + 1;
            this.scrollToElement(this.stepperRef.current);
            this.stepperRef.current.focus({preventScroll: true});
        }
    }

    scrollToElement(ele, yOffset = scrollOffSetStepper) { // USE DEFAULT VALUE FOR STEPPER
        const y = ele.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    focusFirstErroredField(step, errors, values) {
        let fields = []
        switch(step) {
            case 1:
                fields = ['fieldOfStudy', 'degreeLevel'];
                if(this.isRegisteredNurseOptionVisible()) {
                    fields = ['fieldOfStudy', 'isRegisteredNurse', 'degreeLevel'];
                }
                if(values?.fieldOfStudy === 'nondegree') {
                    fields = ['fieldOfStudy', 'flex_Course_Category__c'];
                }
                break;
            case 2:
                fields = ['fullName', 'emailAddress', 'phoneNumber', 'zipcode', 'isMilitary'];
                break;
            case 3:
                fields = ['date', 'time'];
                break;
            default:
                break;
        }

        for(let i=0; i<fields.length; i++) {
            let field = fields[i];
            if(errors[field]) {
                const ele = document.getElementsByName(field)[0]; // CAN CAUSE TROUBLE IF MULTIPLE FORMS ARE THERE;
                this.scrollToElement(ele, scrollOffSetErrors);
                ele.focus({preventScroll: true});
                break;
            }
        }
    }

    isRegisteredNurseOptionVisible() {
        const { initialValues } = this.state;
        return [
            'healthcare',
            'nursing',
        ].indexOf(initialValues.fieldOfStudy) !== -1;
    }

    validate3Step(values, step, isScheduledCall, degreeFlag) {
        const errors = {};
        switch (step) {
          case 1:      
            if (!values.fieldOfStudy) {
                errors.fieldOfStudy = 'Area of study requires a value';
            }
            if (values.fieldOfStudy !== 'nondegree'){
                if (!values.degreeLevel) {
                    errors.degreeLevel = 'Degree level requires a value';
                }
            }
            if (values.fieldOfStudy === 'nondegree'){
                if (!values.flex_Course_Category__c){
                    errors.flex_Course_Category__c = 'Course category requires a value';
                }
            }
            if (this.isRegisteredNurseOptionVisible() && !values.isRegisteredNurse) {
                errors.isRegisteredNurse = 'Registered nurse requires a value';
            }
            break;
          case 2:
            if (!values.postalCode) {
              errors.zipcode = 'Enter a ZIP code';
            }
            if (values.postalCode && values.postalCode.length !== 5) {
              errors.zipcode = 'ZIP codes must include 5 numeric characters';
            }
            if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
              errors.zipcode = 'ZIP code must be numeric';
            } else if (values.postalCode && values.stateProvince === 'N/A') {
              errors.zipcode = 'ZIP code appears invalid';
            }
            if (!values.emailAddress) {
              errors.emailAddress = 'Email address requires a value';
            }
            if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              errors.emailAddress = 'Email address appears invalid';
            } 
            if (!values.phoneNumber) {
                errors.phoneNumber = 'Phone number requires a value';
            }
            if (values.phoneNumber && values.phoneNumber.length !== 10) {
                errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            }
            if (!values.fullName) {
                errors.fullName = 'First and Last name requires a value';
            } else if (values.fullName.trim().indexOf(' ') === -1) {
                errors.fullName = 'Please enter a First and Last name';
            } else if (!profanityCheck(values.fullName)) {
                errors.fullName = 'Full Name appears invalid';
            }
            if (values.isMilitary === '' || values.isMilitary === undefined) {
                errors.isMilitary = 'U.S. Military affiliation requires a value';
            }
            break;
          case 3:
            if(isScheduledCall===true){
                if(!values.date || values.date === null){
                  errors.date = 'Scheduled contact date requires a value';   
                  document.getElementById(dateErrorID).innerHTML = 'Scheduled contact date requires a value';
                  document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                  document.getElementById(dateErrorID).classList.add('Mui-error');
                  document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                } else if (values.date && hasErrorMsg) {
                  let errorMsg= document.getElementById(dateErrorID).innerHTML.toString();
                  errors.date = errorMsg;
                }
                if (!values.time || values.time === null){         
                  errors.time = 'Scheduled contact time requires a value';
                  document.getElementById(timeErrorID).innerHTML = `Scheduled contact time requires a value`;
                  document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
                  document.getElementById(timeErrorID).classList.add('Mui-error');
                  document.getElementById('timePicker').classList.add('uopx-wrapper--error');
                } else if (values.time && hasErrorTimeMsg===true) {
                  errors.time = 'Time must be scheduled at least 30 minutes past current time'
                  document.getElementById(timeErrorID).innerHTML = `Time must be scheduled at least 30 minutes past current time`;
                  document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
                  document.getElementById(timeErrorID).classList.add('Mui-error');
                  document.getElementById('timePicker').classList.add('uopx-wrapper--error');
                }
              }
            break;
          default:
            break;
        }
        if(document.activeElement.name === 'next' || document.activeElement.name === 'submit') {
            this.focusFirstErroredField(step, errors, values);
        }
        return errors;
    }

    validate(values) {
        errors = validateOneStep(values);
        if(this.isAScheduledCall(values)) {     
            if(!formRef?.current?.values.date){
                errors.date = 'Scheduled contact date requires a value';   
                document.getElementById(dateErrorID).innerHTML = 'Scheduled contact date requires a value';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');    
            }
            if (!formRef?.current?.values.time || formRef?.current?.values.time == null){
                errors.time = 'Scheduled contact time requires a value';
                document.getElementById(timeErrorID).innerHTML = `Scheduled contact time requires a value`;
                document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(timeErrorID).classList.add('Mui-error');
                document.getElementById('timePicker').classList.add('uopx-wrapper--error');
            }
            let errorDateMsg;
            let errorTimeMsg;
            if(hasErrorMsg === true) {
                errorDateMsg = document.getElementById('dateError').innerHTML;
            }
            if(hasErrorTimeMsg === true) {
                errorTimeMsg = document.getElementById('timeError').innerHTML;
            }
            if (errorDateMsg) errors.date = errorDateMsg;
            if (errorTimeMsg) errors.time = errorTimeMsg;
        }
        return errors;
    }

    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
            totalSteps,
            isSubmit
        } = this.state;
        return (
            <div className={classes.root}>
                {/* <UOPHeader /> */}
                <main className="rfi schedACallParent">
                    <Grid container justifyContent='center' className="mobileFormContainer schedACall">
                    <Grid container className="maxWidthContainer-oneStepForm">
                        <Grid item md={12} sm={12} lg={6} xs={12} >
                            {
                                !isSubmit ? (
                                    <>
                                    <Grid item md={12} sm={12} lg={12} xs={12} className="stepCounter">
                                        <Typography
                                            style={{fontSize:'1.6rem'}}
                                            variant='body2'
                                            component='div'
                                            tabIndex={-1}
                                            // className={classes.stepCounter}
                                            ref={this.stepperRef}
                                        >
                                            Step <span className="activeStepCount">{activeStep}</span> of <span className="totalStepsCount">{totalSteps}</span>
                                        </Typography>
                                    </Grid>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                            <Typography
                                                variant='body2'
                                                component='h2'
                                                className='mainHeader'
                                                // style={{ background: 'linear-gradient(90deg, #e3f5fa 3%, #92c8d6 100% )', margin: '-24px -24px 24px', padding: '24px', fontSize: '21px' }}
                                                // tabIndex={-1}
                                            >
                                                {
                                                    activeStep === 1 ? h1Step1:
                                                    activeStep === 2 ? h1Step2:
                                                    h1Step3
                                                }
                                            </Typography>
                                        </Grid>
                                    <Grid item md={12} sm={12} lg={12} xs={12}>
                                        {
                                            activeStep === 1 && 
                                            <Formik
                                                initialValues={initialValues}
                                                onSubmit={this.handleSubmit}
                                                validate={(values) => this.validate3Step(values, this.state.activeStep)}
                                                innerRef={form => formRef.current = form}
                                                validateOnChange>
                                                {(props) => {
                                                return (
                                                    <Form className="mobile-form"
                                                        aria-label="Request For Information"
                                                        style={{ position: 'relative' }}
                                                        onChange={this.handleAnalyticsRFIFormStart}>
                                                            <fieldset style={{borderStyle:'none'}}>
                                                            <div className="newOneForm-SubTitle">
                                                                <legend>
                                                                {
                                                                    subHeaderStep1
                                                                }
                                                                </legend>
                                                            </div>
                                                                <Typography
                                                                    variant='body2'
                                                                    component='div'
                                                                    className="reqinfo"
                                                                >
                                                                    * Required information
                                                                </Typography>
                                                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                <Typography
                                                                            variant='body2'
                                                                            component='div'
                                                                            className='customLabel'
                                                                            id="areaOfStudy"
                                                                        >
                                                                            Which area of study are you interested in?
                                                                        </Typography> <br></br>
                                                                <SelectField
                                                                        id="fieldOfStudy"
                                                                        name="fieldOfStudy"
                                                                        label="Area of study *"
                                                                        ariaRequired="true"
                                                                        data={dynamicFieldOfStudyDropdownVariant}
                                                                        disabled={props.values.fosisdisable || false}
                                                                        className="MuiFormControl-fullWidth"
                                                                        formRef={formRef}
                                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                                        onChange={this.dropdownChange("fieldOfStudy", props.values.fieldOfStudy)}
                                                                    ></SelectField>
                                                                    {roleChange()}
                                                                    </Grid>
                                                                    {conditionalRadio(props.values.fieldOfStudy) && <>
                                                                        <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm" style={{ maxWidth: '100%'}}>
                                                                            <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} row={true} className="selectRadioLabel" onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse?*" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                                        </Grid>
                                                                        </>}
                                                                        {props.values.fieldOfStudy !== "nondegree" && <>
                                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                        <Typography
                                                                            variant='body2'
                                                                            component='div'
                                                                            className='customLabel'
                                                                            id="degreeLevelLabel"
                                                                        >
                                                                            Which degree level are you working toward?
                                                                        </Typography> <br></br>
                                                                    <SelectField
                                                                        id="degreeLevel"
                                                                        name="degreeLevel"
                                                                        label="Degree level *"
                                                                        data={this.state.degreeLevel}
                                                                        className="MuiFormControl-fullWidth"
                                                                        ariaRequired="true"
                                                                        formRef={formRef}
                                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                                        onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                                    ></SelectField>
                                                                    {roleChange()}
                                                                    </Grid>
                                                                    </>}
                                                                    {props.values.fieldOfStudy === "nondegree" && <>
                                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                        <SelectField
                                                                            id="flex_Course_Category__c"
                                                                            name="flex_Course_Category__c"
                                                                            label="Course category *"
                                                                            data={courseCategoryDropdown}
                                                                            className="MuiFormControl-fullWidth"
                                                                            formRef={formRef}
                                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                                            onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                                        ></SelectField>
                                                                        {roleChange()}
                                                                    </Grid>
                                                                    </>}
                                                                    </fieldset>
                                                                    {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">                                     
                                                                            <SingleCheckbox
                                                                                    className="cbe-checkbox"
                                                                                    id="isCBE"
                                                                                    label={unescapeHTML(this.getCBEText())}
                                                                                    ariaLabel={unescapeHTML(this.getCBEText())}
                                                                                    checkboxName="isCBE"
                                                                                    parentCallback={this.isCBEConsent}
                                                                                />
                                                                        </Grid>
                                                                    </>}
                                                                    <div className="buttons">
                                                                        <UOPXButton name='next' size='large' variant='outlined' type='submit' className="btn-submit" style={{border: '2px solid #DB3725', background: '#FFFFFF'}}>
                                                                            Next
                                                                        </UOPXButton>
                                                                    </div>
                                                                    {/* <FormikErrorFocus offset={-50} align={'middle'} focusDelay={200} ease={'linear'} duration={1000} /> */}
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        }
                                        {
                                            activeStep === 2 && 
                                            <Formik
                                                initialValues={initialValues}
                                                onSubmit={this.handleSubmit}
                                                validate={(values) => this.validate3Step(values, this.state.activeStep)}
                                                innerRef={form => formRef.current = form}
                                                validateOnChange>
                                                {(props) => {
                                                return (
                                                    <Form className="mobile-form"
                                                        aria-label="Request For Information"
                                                        style={{ position: 'relative' }}
                                                        onChange={this.handleAnalyticsRFIFormStart}>
                                                            <fieldset style={{borderStyle:'none'}}>
                                                            <div className="newOneForm-SubTitle">
                                                                <legend>
                                                                {
                                                                    subHeaderStep2
                                                                }
                                                                </legend>
                                                            </div>
                                                                <Typography
                                                                    variant='body2'
                                                                    component='div'
                                                                    className="reqinfo"
                                                                >
                                                                    * Required information
                                                                </Typography>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                <MaterialInput name="fullName" id="fullName"
                                                                    label="First and Last name *" ariaRequired="true" ariaLabel="Enter Full name"  />
                                                                </Grid>
                                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                                </Grid>
                                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                    <MaterialPhoneNumber name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                                </Grid>
                                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                    <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-describedby="Enter Zip code" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef}></ZipCode>
                                                                </Grid>
                                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm" style={{ maxWidth: '100%'}}>
                                                                    <MaterialRadio className="selectRadioLabel" value={formRef?.current?.values.isMilitary} row onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation?*" name="isMilitary" ariaLabel="Do you have any U.S. Military affiliation?" />
                                                                </Grid>
                                                            </fieldset>
                                                                <div className="buttons">
                                                                    <UOPXButton name='next' size='large' variant='outlined' type='submit' className="btn-submit" style={{border: '2px solid #DB3725', background: '#FFFFFF'}}>
                                                                        Next
                                                                    </UOPXButton>
                                                                    <div className="backButton">
                                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                                    </div>
                                                                </div>
                                                                {/* <FormikErrorFocus offset={-50} align={'middle'} focusDelay={200} ease={'linear'} duration={1000} /> */}
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        }
                                        {
                                            activeStep === 3 &&  
                                            <Formik
                                                initialValues={initialValues}
                                                onSubmit={this.handleSubmit}
                                                validate={(values) => this.validate3Step(values, this.state.activeStep, schedACallFlag === 'Yes')}
                                                innerRef={form => formRef.current = form}
                                                validateOnChange>
                                                {(props) => {
                                                return (
                                                    <Form className="mobile-form"
                                                        aria-label="Request For Information"
                                                        style={{ position: 'relative' }}
                                                        onChange={this.handleAnalyticsRFIFormStart}>
                                                            <fieldset style={{borderStyle:'none'}}>
                                                            <div className="newOneForm-SubTitle">
                                                                <legend>
                                                                {
                                                                    subHeaderStep3
                                                                }
                                                                </legend>
                                                            </div>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm schedule-call">
                                                                <MaterialRadio value={formRef?.current?.values?.isAScheduledCall ? formRef?.current?.values?.isAScheduledCall : schedACallFlag } row={true} className="selectRadioLabel" onChange={this.handleChange} items={scheduleACallRadioOption} label="" name='isScheduledCall' ariaLabel=""></MaterialRadio>
                                                            </Grid>
                                                            {
                                                                //formRef.current.values.flex_rfiCallback
                                                                schedACallFlag === 'Yes' &&
                                                                <Grid item md={12} sm={12} lg={12} xs={12}>
                                                                <fieldset id="dateTimeFieldSetSchedACall" style={{borderStyle:'none'}}>
                                                                <Grid id='dateGrid' item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm"> 
                                                                <div id='datePicker' className="uopx-wrapper uopx-wrapper--default">
                                                                <label htmlFor="date" aria-label="Schedule Contact Date" required>{scheduledContactDateLabel}</label>
                                                                    <UOPDatePicker
                                                                        identifier="date" 
                                                                        min={today}
                                                                        max={endDate}  
                                                                        aria-describedby={dateErrorID}
                                                                    />
                                                                    <p id='dateError' role="alert" style={{margin:'0'}}></p>
                                                                </div>
                                                                {formatDateandWeekend(this)}
                                                                </Grid>
                                                                <Grid id='timeGrid' item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                <div id='timePicker' className="uopx-wrapper uopx-wrapper--default">
                                                                <label htmlFor="UOPTime">Schedule contact time *</label>
                                                                    <TimePicker
                                                                        id="UOPTime"
                                                                        name="time" // This need to match formValue schema
                                                                        aria-label="Schedule contact time"
                                                                        data={apptsAvailable}
                                                                        passedTime={e => this.onTimePicked(e,props)}                                                                                                                              
                                                                        //onChange={e => this.onTimePicked(e,props)}
                                                                        ariaDescribedby={timeErrorID}
                                                                    ></TimePicker>
                                                                    <p id='timeError' role="alert"></p>
                                                                </div>
                                                                </Grid>                                                                                                
                                                                </fieldset> 
                                                                </Grid>
                                                            }
                                                            {
                                                                //formRef.current.values.flex_rfiCallback
                                                                schedACallFlag === 'No' &&
                                                                <Grid item md={12} sm={12} lg={12} xs={12}>
                                                                    <Typography
                                                                        style={{
                                                                                fontFamily: 'Roboto',
                                                                                fontSize: '16px',
                                                                                fontWeight: '500',
                                                                                lineHeight: '24px',
                                                                        }}
                                                                    >
                                                                        Hours of operations:
                                                                    </Typography>
                                                                    <ul style={{ listStyleType: 'none', paddingLeft: '0px', fontSize: '16px' }}>
                                                                        <li>Monday - Friday, 5am to 7pm MST</li>
                                                                        <li>Saturday, 6am to 5pm MST</li>
                                                                        <li>Sunday, 12pm to 4pm MST</li>
                                                                    </ul>
                                                                </Grid>
                                                            }
                                                            </fieldset>
                                                            <div className="disclaimer">
                                                            {unescapeHTML(disclaimerText)}
                                                            </div>
                                                            <div className="buttons">
                                                                <UOPXButton name='submit' size='large' variant='outlined' type='submit' className="btn-submit" style={{border: '2px solid #DB3725', background: '#FFFFFF'}}>
                                                                    Schedule our conversation
                                                                </UOPXButton>
                                                                <div className="backButton">
                                                                    <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                                </div>
                                                            </div>
                                                            {/* <FormikErrorFocus offset={-50} align={'middle'} focusDelay={200} ease={'linear'} duration={1000} /> */}
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        }
                                    </Grid>
                                    </>
                                ) :(
                                    <div className='thankyoumessage'>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                            <h1 className="newOneForm-title" role="heading" aria-level="1">
                                                { thankYouPageHeader }
                                            </h1>
                                        </Grid>
                                        <div className="newOneForm-SubTitle">
                                            { 
                                                schedACallFlag === 'Yes' ?
                                                unescapeHTML(thankYouPageSubTitlePart1dateTime + `<strong>${displayDate} ${hourFormatter(displayTime)}</strong>` + thankYouPageSubTitlePart2dateTime) :
                                                unescapeHTML(thankYouPageSubTitleASAP)
                                            }
                                            {/* inner */}
                                        </div>
                                        <ul style={{ fontSize: '16px' }}>
                                            <li>Skills-focused degree programs</li>
                                            <li><strong>Ways to save</strong> time and money</li>
                                            <li>Our real-world faculty</li>

                                        </ul>
                                        <Typography
                                            variant='body2'
                                            component='div'
                                            className='customLabel'
                                            id="areaOfStudy"
                                        >
                                            If you choose to enroll, in most cases <strong>we can request your transcripts</strong> at no cost to you. 
                                        </Typography>
                                    </div>
                                )
                            }
                            
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}></Grid>
                        <Grid container md={12} sm={12} lg={4} xs={12} className="maxWidthContainer-twoGridoneStepForm" alignItems={'center'} justify={'flex-end'}>
                            <div className="oneForm-Rectangleline">
                                <img src={imagePath} alt="" style={{ width: "100%" }}></img>  
                            </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
                {/* <Footer />  */}
            </div>
        );
    }
    isAScheduledCall(values) {
        const {degreeLevel, fieldOfStudy, isScheduledCall} = values;
        if((fieldOfStudy === 'technology' || degreeLevel === 'masters') && this.state.isScheduledCallRadio === true){
            return true;
        }
    }
}

ScheduleACall.defaultProps = {
    classes: {},
};

ScheduleACall.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

//export default withStyles(styles)(ScheduleACall);

const ScheduleACallFormStyled = withStyles(styles)(ScheduleACall);

const generateClassName = createGenerateClassName({
    productionPrefix: 'schCall',
});

export default function (props) {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ScheduleACallFormStyled {...props} />
        </StylesProvider>
    );
}




const validateDate = (value, time) => {    
    // console.log('values passed into validateDate ', value, time );    
    let parsedDateObject = '';
    if (value === null) {        
        const todaysDate = moment().format("YYYY-MM-DD");
        const dateObject = new Date(`${todaysDate}T${time}`);
        parsedDateObject = Date.parse(dateObject);
        dateHasBeenChosen=false;
    //    console.log("parsedDateObject value null ", parsedDateObject);
    } else {
        const passedDateValue = moment(value).format("YYYY-MM-DD");
        const dateObject = new Date(`${passedDateValue}T${time}`);
        parsedDateObject = Date.parse(dateObject);
        dateHasBeenChosen=true;
    //    console.log("parsedDateObject value NOT null ", parsedDateObject);
    }
    let isValidTime = false;
    const date = new Date(value); // Fri Apr 22 2022 00:00:00 GMT-0700 (Mountain Standard Time)
    const timeInThirty = new Date().setMinutes(new Date().getMinutes() +30);
    if (timeInThirty<parsedDateObject){
        isValidTime = true
    }
    const dateString = new Date(value).toLocaleDateString('en-US',{
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }); // 4/22/2022
    const timeInThirtyFormatted = moment(timeInThirty).format('h:mm A'); // 3:45 PM
    displayDate = dateString;
    displayTime = time;
    //console.log('isDateValid is ', isDateValid); // True / False
    if(dateHasBeenChosen===true){
        // Passes in selected date in this format: Sat Apr 02 2022 00:00:00 GMT-0700 (Mountain Standard Time) 
    // If no value then run this code and return Date is required
    // Date validation
         if (date === null || date === undefined || date === '') { // date is undefined, null or empty string
            console.log('Date is required')
            document.getElementById(dateErrorID).innerHTML = 'Date is required';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            document.getElementById(dateErrorID).setAttribute('aria-invalid', true);
            document.getElementById(dateErrorID).setAttribute('aria-errormessage', 'Date is required');            
            hasErrorMsg = true;
        }
        else if (isDateValid===true && Date.parse(dateString) < Date.parse(new Date().toLocaleDateString('en-US'))){ // if parsed date is earlier than current date
            console.log('Date must be in the future', date, new Date());
            document.getElementById(dateErrorID).innerHTML = 'Date must be in the future';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
            //return false;
        }
        else if (isDateValid===true && !isWeekend(date) && moment(date).format("YYYY-MM-DD")==="2023-12-25") { // if date is christmas
            document.getElementById(dateErrorID).innerHTML = 'Company holiday';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
        }
        else if (isDateValid===true && isWeekend(date)) { // if date is a sunday
            console.log(moment(date).format("YYYY-MM-DD"));
            if(moment(date).format("YYYY-MM-DD")==="2023-12-25"){
                document.getElementById(dateErrorID).innerHTML = 'Company holiday';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            } else {
                console.log('Please select a day other than Sunday');
                document.getElementById(dateErrorID).innerHTML = 'Please select a day other than Sunday';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            }
            //return false;
        }
        else if (isDateValid===true && (Date.parse(date)>=Date.parse(endDate) && moment(date).format("LLL") !== moment(endDate).format("LLL"))) { // if parsed date is further ahead than 5 business days
            console.log("Please select a date within the next 5 business days");
            document.getElementById(dateErrorID).innerHTML = 'Please select a date within the next 5 business days';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
        }
        else if ((isDateValid === true) && (date !== null) && (Date.parse(dateString) >= Date.parse(new Date().toLocaleDateString('en-US'))) && (isWeekend(date) === false)) { // if everything is good
            console.log("In else condition, everything all good");
        //    console.log(timeValue, timeInThirtyFormatted);
            if(document.getElementById(dateErrorID).innerHTML.length>0){
                document.getElementById(dateErrorID).innerHTML = '';
            }
            if(document.getElementById(dateErrorID).classList.contains('MuiFormHelperText-root') && document.getElementById(dateErrorID).classList.contains('Mui-error')) {
                document.getElementById(dateErrorID).classList.remove('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.remove('Mui-error');
            }
            if(document.getElementById('datePicker').classList.contains('uopx-wrapper--error')){
                document.getElementById('datePicker').classList.remove('uopx-wrapper--error');
            }
            hasErrorMsg = false;
            formRef.current.values.date=date;            
        }
        else { // catch all
            console.log("Date is invalid");
            document.getElementById(dateErrorID).innerHTML = 'Date is invalid';
            document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(dateErrorID).classList.add('Mui-error');
            document.getElementById('datePicker').classList.add('uopx-wrapper--error');
            hasErrorMsg = true;
            //return false;
        }
    }
    // Time validation
    if(isDateValid === true && dateString === moment().format('L') && isValidTime === false) {
    //    console.log(isDateValid, dateString===moment().format('L'), isValidTime);
        document.getElementById(timeErrorID).innerHTML = `Time must be scheduled at least 30 minutes past current time`;
        document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
        document.getElementById(timeErrorID).classList.add('Mui-error');
        document.getElementById('timePicker').classList.add('uopx-wrapper--error');
        hasErrorTimeMsg = true;
    } else {
        if(document.getElementById(timeErrorID).innerHTML.length>0){
            document.getElementById(timeErrorID).innerHTML = '';
        }
        if(document.getElementById(timeErrorID).classList.contains('MuiFormHelperText-root') && document.getElementById(timeErrorID).classList.contains('Mui-error')) {
            document.getElementById(timeErrorID).classList.remove('MuiFormHelperText-root');
            document.getElementById(timeErrorID).classList.remove('Mui-error');
        }
        if(document.getElementById('timePicker').classList.contains('uopx-wrapper--error')){
            document.getElementById('timePicker').classList.remove('uopx-wrapper--error');
        }
        hasErrorTimeMsg = false;        
    }
}

function isWeekend(date) {
    let today = moment().format("YYYY-MM-DD");
    if(today === (moment().dayOfYear(359).format("YYYY-MM-DD"))){
        return date.getDay() === 0 || date.getDay() === 6
    } else {
        return date.getDay() === 0
    }
}
function isValidDate(date) {
    let timestamp = Date.parse(date);
    if (isNaN(timestamp) == false && timestamp > 0) {
        let d = new Date(timestamp);
        isDateValid = true;
    } else {
        isDateValid = false;
    }
}
function formatDateInput(input){
    let inputValue = input.replace(/\D/g, '');
    let inputValueArr = inputValue.split('');
    if (inputValueArr.length > 2 && inputValueArr.length <=4){ // Input looks like 1020
        inputValueArr.splice(2,0,'/');
    } else if (inputValueArr.length > 4) { // Input looks like 10202
        inputValueArr.splice(2,0,'/');
        inputValueArr.splice(5,0,'/');
    }
    inputValue = inputValueArr.join('');
    let checkValueArr = inputValue.split('/');
    if (checkValueArr[0]) checkValueArr[0] = checkValue(checkValueArr[0], 12);
    if (checkValueArr[1]) checkValueArr[1] = checkValue(checkValueArr[1], 31);
    inputValue = checkValueArr.join('/');
    inputValue = inputValue.substring(0,10);
    /* if(inputValue.length===1){
        phArr.splice(0,1,inputValue.charAt(0));
    } else if(inputValue.length===2){
        phArr.splice(1,1,inputValue.charAt(1));
    } else if(inputValue.length===3){
        phArr.splice(2,1,inputValue.charAt(2));
    } else if(inputValue.length===4){
        phArr.splice(3,1,inputValue.charAt(3));
    } else if(inputValue.length===5){
        phArr.splice(4,1,inputValue.charAt(4));
    } else if(inputValue.length===6){
        phArr.splice(5,1,inputValue.charAt(5));
    } else if(inputValue.length===7){
        phArr.splice(6,1,inputValue.charAt(6));
    } else if(inputValue.length===8){
        phArr.splice(7,1,inputValue.charAt(7));
    } else if (inputValue.length >= 9) {
        phArr.slice(0,8);
    } */
    /* newPhArr.splice(0,1,(phArr[0]+phArr[1]));
    newPhArr.splice(1,1,(phArr[2]+phArr[3]));
    newPhArr.splice(2,1,(phArr[4]+phArr[5]+phArr[6]+phArr[7]));
    if (newPhArr[0]) newPhArr[0] = checkValue(newPhArr[0], 12);
    if (newPhArr[1]) newPhArr[1] = checkValue(newPhArr[1], 31); */

    //input = newPhArr.join('/');
    input = inputValue;
    return input;
}

function checkValue(str, max) { // ex (13,12)
    if (str.includes('m')|| str.includes('d')|| str.includes('y')){
        return str;
    }
    
    if (str.charAt(0) !== '0' || str == '00') {
      let num = parseInt(str); // parses '13' > 13
      if (isNaN(num) || num <= 0 || num > max) num = '01'; // isNaN(13):false || num<= 0 :false || num > max : true -> num = 1;
        if(num == '01'){
            str=num 
        } else {
            str=num.toString();
        } 
    };
    return str;
};

function formatDateandWeekend (instance){    
    clearTimeout(instance.duetTimeout);
    instance.duetTimeout = setTimeout(function(){
        const picker = document.querySelector("duet-date-picker");  
    //    picker.isDateDisabled = isWeekend;              
        let scheduleYourCallHeader = document.getElementsByClassName('duet-date__mobile-heading');
        scheduleYourCallHeader.ariaLabel = 'Scheduled your call in the next few days';
        scheduleYourCallHeader.tabindex=0;
        let dateOption = document.getElementsByClassName('duet-date__day is-month');
        dateOption.tabIndex = 0;
        let closeButton = document.getElementsByClassName('duet-date__close');
        closeButton.ariaLabel = 'Close button';

        if(document.querySelector("#datePicker input") === null)
            return;

        document.querySelector("#datePicker input").ariaRequired='true';
        document.querySelector("#datePicker input").ariaLabel='schedule contact date';
        document.querySelector("#datePicker input").name='date';
        document.querySelector("#datePicker input").setAttribute("aria-describedby", dateErrorID);
        /* picker.addEventListener("duetFocus", function(e) {
            document.querySelector("#datePicker label").innerHTML='Schedule contact date * (mm/dd/yyyy)';
        }); */
        picker.addEventListener("duetBlur", function(e) { // Fires once the input has been unfocused
            document.querySelector("#datePicker label").innerHTML=scheduledContactDateLabel;
            let input = document.getElementById('date').value;
            if(input !== ''){
                dateValue = input;                
            }
            isValidDate(dateValue);
            validateDate(dateValue,timeValue);
        });
       /*  picker.addEventListener("duetFocus", function(e) { // Fires once input is focused
            let input = document.querySelector('.duet-date__input');
            if (input.value.length === 0){
                input.value = "mm/dd/yyyy"
            }
            
        }); */
        picker.addEventListener("duetChange", function(e) { // Fires once a date has been chosen
            dateValue = e.detail.valueAsDate;
            isValidDate(dateValue);
            validateDate(dateValue,timeValue);
        });        
       /*  picker.addEventListener('keydown', function (e) {
            if (e.key === 'Backspace' || e.key === 'Delete') {
                let input = document.querySelector('.duet-date__input');                
                input.value = formatDateInput(input.value);
                let inputValue = input.value.replace(/\//g,'');
                inputValue = inputValue.split('');            
                if(Number.isInteger(parseInt(inputValue[7]))===true){
                    inputValue.splice(7,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[6]))===true && inputValue[7] === 'y'){
                    inputValue.splice(6,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[5]))===true && inputValue[6] === 'y'){
                    inputValue.splice(5,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[4]))===true && inputValue[5] === 'y'){
                    inputValue.splice(4,1,'y');
                } else if(Number.isInteger(parseInt(inputValue[3]))===true && inputValue[4] === 'y'){
                    inputValue.splice(3,1,'d');
                } else if(Number.isInteger(parseInt(inputValue[2]))===true && inputValue[3] === 'd'){
                    inputValue.splice(2,1,'d');
                } else if(Number.isInteger(parseInt(inputValue[1]))===true && inputValue[2] === 'd'){
                    inputValue.splice(1,1,'m');                
                } else if(Number.isInteger(parseInt(inputValue[0]))===true && inputValue[1] === 'm'){
                    inputValue.splice(0,1,'m');                    
                }                
                phArr=inputValue;                
                input.value=phArr.join('')                
            }
        }); */
        picker.addEventListener('input', (e) => {
            let input = document.querySelector('.duet-date__input');
            let newPh = [];
            if(input.value.length > 7) {
                let ph  = input.value.split('/');   
                for(let i = 0; i<3; i++){
                    if (ph[i].length === 1 && ph[i] != "0" && i<2 ) {
                        ph[i]=ph[i].padStart(2,'0');                    
                    }
                    newPh.push(ph[i])
                }                             
            }
            if(newPh.length === 0){
                input.value = formatDateInput(input.value);
            } else {
                input.value = formatDateInput(newPh.join('/'));
            }
            
        });
    /*    const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
        picker.dateAdapter = {
            parse(value = "", createDate) {
                const matches = value.match(DATE_FORMAT_US)
                if (matches) {
                return createDate(matches[3], matches[1], matches[2])
                }
            },
            format(date) {
                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            },
        }
        picker.localization = {
            buttonLabel: "Choose date",
            placeholder: "mm/dd/yyyy",
            selectedDateMessage: "Selected date is",
            prevMonthLabel: "Previous month",
            nextMonthLabel: "Next month",
            monthSelectLabel: "Month",
            yearSelectLabel: "Year",
            closeLabel: "Close window",
            calendarHeading: "Schedule your call in the next few days",
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            locale: "en-US",
          } */
    }, 1000);   
}