import React, { useEffect, useRef } from "react";
import moment from 'moment';

function useListener(ref, eventName, handler) {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener(eventName, handler)
      return () => element.removeEventListener(eventName, handler)
    }
  }, [eventName, handler, ref])
}

function isWeekend(date) {
  //console.log(date);
  let newDate = moment(`${date}`).format('YYYY-MM-DD');
  let today = moment().format("YYYY-MM-DD");
  let christmas = moment("12-25-2023").format("YYYY-MM-DD");
  if(today === (moment().dayOfYear(359).format("YYYY-MM-DD"))){
      return date.getDay() === 0 || date.getDay() === 6
  } else if (newDate === christmas) {
    return true
  } else {
    return date.getDay() === 0
  }
}

export function UOPDatePicker({
  onChange,
  onFocus,
  onBlur,
  onOpen,
  onClose,
  dateAdapter,
  localization,
  ...props
}) {
  const ref = useRef(null)
  useListener(ref, "duetChange", onChange)
  useListener(ref, "duetFocus", onFocus)
  useListener(ref, "duetBlur", onBlur)
  useListener(ref, "duetOpen", onOpen)
  useListener(ref, "duetClose", onClose)

  setTimeout(function(){
  const picker = document.querySelector("duet-date-picker");
  if(picker === null)
    return;
  
  picker.isDateDisabled = isWeekend;
  const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
  
  picker.dateAdapter = {
    parse(value = "", createDate) {
        const matches = value.match(DATE_FORMAT_US)
        if (matches) {
        return createDate(matches[3], matches[1], matches[2])
        }
    },
    format(date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
  }

  picker.localization = {
    buttonLabel: "Choose date",
    placeholder: "mm/dd/yyyy",
    selectedDateMessage: "Selected date is",
    prevMonthLabel: "Previous month",
    nextMonthLabel: "Next month",
    monthSelectLabel: "Month",
    yearSelectLabel: "Year",
    closeLabel: "Close window",
    calendarHeading: "Schedule your call in the next few days",
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    locale: "en-US",
  }
  }, 100); 

  useEffect(() => {
    ref.current.localization = localization
    ref.current.dateAdapter = dateAdapter
  }, [localization, dateAdapter])

  return <duet-date-picker ref={ref} {...props}></duet-date-picker>
}

