import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { Typography, Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import date from 'date-and-time';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { submitForm, mapAdditionalFields, feedDataToSessionStorage, unescapeHTML, userData, fireEvent, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid, profanityCheck } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import { initValues, processJSONParameter } from '../common/configTools';


const formRef = {};
let defaultTextHTML = '<h2 id="dialogTitle-BGinline" class="newOneForm-title" style="display:flex; align-items:center; justify-content: center; margin-top:0;">Free Business Programs Guide</h2>';
let defaultDescriptionTextHTML = '<div id="dialogDesc-BGinline" class="newOneForm-SubTitle" style="font-weight: 400">Make informed decisions with inside details about our business programs, the skills you’ll earn, the faculty who’ll teach you and more.</div>';
 const initialEmailFormValues = {
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    flex_more_info__c: '',
    flex_Info_Requested__c: 'something_else',
    appContext: 'EMAILCAPTURE',
    mcid: '',
    useCaseContext: 'NEW',
    flex_EmailCaptureSource: 'BUSG',
    userAgent: '',
    campaign: 'PHXEDU10',
    source: 'INET',
    requestingIP: '',
    requestingUrl: '',
    disclaimer: 'This information is available on <a tabindex="0" href="https://www.phoenix.edu/">phoenix.edu</a> but has been organized into one central source for you to reference quickly and easily. By submitting this form, you consent to receiving University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy_policy.html">Privacy Policy</a> for more information.',
  };
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let preProspectInfoData = {};
let device;
let tcValue = '';
//const isTabletView = window.innerWidth <= 990;
const isMobileView = window.innerWidth <= 640;
let formStep;
let formType;
let formName;
let formLocation;

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");            
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
}
/// Not currently being used -- it is being used on the XF/Target test but is defined there.
/* function trapFocus(element, focusElmtValue) {
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    var firstFocusableEl = focusableEls[focusElmtValue];  
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { 
        return; 
      }
      if ( e.shiftKey )  shift + tab  {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else  tab  {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  } */

class BusinessGuideInline extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
        this.ref = {
            container: React.createRef(),
            form: React.createRef(),
        };
    }
    reset() {
        this.setState(this.initialState);
    }

    componentDidMount() {
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues.flex_EmailCaptureSource = 'BUSG';
        initialValues.thankyouPageUrl= '';
        initValues(initialValues, this.props); 
        this.setState({ initialValues });  
        getIPAddress(this.updateIPAddress);
        
        if (isMobileView){device = 'Mobile';}else {device = 'Desktop';}
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Preprospect Zero Party';
        formLocation = 'Zero Party Inline Business Program'
    	formName = this.props.formname || initialValues.formname || 'Inline Business Program Guide ';
        formName = formName + device;
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formLocation"] = formLocation;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }

        // KEYDOWN EVENT
        if (document.querySelector('#rfiBG-inline .abandonment input[name="fullName"]')) {
            document.querySelector('#rfiBG-inline .abandonment input[name="fullName"]').addEventListener("keydown", function(e) {
                if(e.keyCode == 9 || e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */  {} 
                    else {
                        setTimeout(function () {
                            if (document.querySelector('#rfiBG-inline [id^="fullName"][id$="-helper-text"]')) {
                                document.querySelector('#rfiBG-inline input[name="emailAddress"]').focus()
                            }
                        }, 300);
                    }
                }
            });
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.requestingIP = ipAddress;
        this.setState({ initialValues });    
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireEvent('InlineBusinessProgramGuideStart', 'Zero Party', formName, formType, 'Start inline business program guide', formLocation);
            this.setState({ rfi_begin: true });
        }
      }    
      
    isTransferCredit = (event, checked) => {
        formRef.current.values.flex_Transfer_Credits_from_ValueExchange__c = checked;
        if (checked === true) {
            tcValue='checked';
        }
        if (checked === false) {
            tcValue='unchecked';
        }      
    }

    handleSubmit = async values => {
      if (document.activeElement.id === "BGInlineSubmit") {
        if (((values.fullName).trim().indexOf(' ')) > 0) {
            const i = (values.fullName.trim()).indexOf(' ');
            values.firstName = (values.fullName.trim()).substring(0, i); 
            values.lastName = (values.fullName.trim()).substring(i + 1);
        }    
        this.setState({ initialValues: values });  
        window.uopSegment&&window.uopSegment.identify({'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress});
        window.uopSegment&&window.uopSegment.track('Inline Business Program Guide Submitted', {'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress, 'analytics': {'mcid': ecidValue}});
        //window.uopxDataLayer&&window.uopxDataLayer.track('PreProspectInfoSubmitted', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'formInformation': {'profileDetails':{'firstName':values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}}, 'external': {'segmentAnonymousId': window.analytics.user().anonymousId(), 'segmentUserId': window.analytics.user().id()}});
        srSpeak('email submitted');
        //submitForm(values, this.props);
        //fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //this.setState({ isSubmit: true, currentStep:2}); 
        mapAdditionalFields(values);
        feedDataToSessionStorage(values);
        values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
        Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

        let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
        }
        
        try {
            const response = await fetch(postURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
            });

            this.setState({
                initialValues: values,
                isSubmit: true,
                currentStep:2,
                message: null,
            });

            if (window.uopxDataLayer && window.uopxDataLayer.track) {
                window.uopxDataLayer&&window.uopxDataLayer.track('InlineBusinessProgramGuideSubmit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Submit inline business program guide', 'location': formLocation, 'formInformation': {'profileDetails':{'firstName' :values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}, 'formDetails':{'campaign' : 'Scholarship Transfer Credits', 'campaignOption' : tcValue},},});
            }
        } catch (e) {
        this.setState({
            message: <div className="">{e.message || e}</div>,
        });
        }
        if (window.FS) {
            preProspectInfoData.source= "BusinessProgramGuideInlineForm";
            //calling FS and passing the tracking data set
            window.FS.event('Submitted PreProspectInfo', preProspectInfoData);
        }
        
        //document.querySelector('#announce').innerHTML = 'email submitted';
        document.querySelector('#downloadcta-BGinline').focus(); 
      }   
    }
    
    validateBusinessGuideForm(values) {
        const errors = {};
        if (!values.fullName) {
            errors.fullName = 'First and Last name requires a value';
          } else if (values.fullName.trim().indexOf(' ') === -1) {
            errors.fullName = 'Please enter a First and Last name';
          } else if (!profanityCheck(values.fullName)) {
            errors.fullName = 'Full Name appears invalid';
          }
        if (!values.emailAddress) {
          errors.emailAddress = 'Email Address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errors.emailAddress = 'Email address appears invalid';
        }
        return errors;
    }

    handleDownload =(e)=> {
        //window.open('https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/scholarship-guide.pdf', "_blank", "noreferrer");
        const pdfUrl = 'https://www.phoenix.edu/content/dam/edu/degrees/doc/business-programs-guide.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.role = 'link'
        link.target='_blank';
        link.download = "business-programs-guide.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.uopxDataLayer.track("InlineBusinessProgramGuideDownload",{
            'componentName' : 'Zero Party',
            'name': formName, 
            'type': formType,
            'text': 'Download inline business program guide',
            'location': formLocation
        });
    }
    
    keydownDownload = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.handleDownload;
        }         
    }
      
    render() {
        const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = this.props.title || defaultTextHTML;
        let descriptionText = this.props.description|| initialValues.description  || defaultDescriptionTextHTML;
        let cta = this.props.cta|| initialValues.cta  || 'Get my guide';
        //if (isTabletView){cta = 'Request my guide';}
        let thankyouMessageTitle = this.props.thankyoumessagetitle|| initialValues.thankyoumessagetitle  || '<h2 id="thanksdialogtitle-BGinline" class="mainHeader" style="display:flex; align-items:center; justify-content: center; text-align:center; margin-top:0;">Thanks for requesting the Business Programs Guide</h2>';
        let thankyouMessageDescription = this.props.thankyoumessagedescription|| initialValues.thankyoumessagedescription  || '<div id="thanksdialogdesc-BGinline" class="newOneForm-SubTitle thanks" style="display:flex; text-align:center; align-items:center; justify-content: center; margin-top:0; font-size:16px">Download PDF now. Or access the link in our email.</div>';

        return (    
            <div id="rfiBG-inline" className="inlineFormContainer">
            {/* <UOPHeader /> */}
                <section
                    aria-label="Request my guide"
                    className="businessGuideInlineFormGrid"
                >
                    <Grid container className="inlineFormGrid" ref={this.ref.container}>
                    <Grid container justifyContent='center' className="mobileFormContainer">
                        <Grid container className="maxWidthContainer-oneStepForm">
					    {/*	<Grid item md={12} sm={12} lg={12} xs={12} >
						<div>{unescapeHTML(titleText)}</div>
                        <div className="newOneForm-SubTitle" style={{ fontSize: '18px', fontWeight: 700 }}>Email us &mdash; and we’ll get the ball rolling.</div>
                        <div className="reqinfo" > * Required information </div>
						</Grid>   */}
                                <Grid item md={12} sm={12} lg={12} xs={12}>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateBusinessGuideForm(values)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange>
                                        <Form className="mobile-form"
                                            aria-labelledby={isSubmit? "thanksdialogtitle-BGinline thanksdialogdesc-BGinline" : "dialogTitle-BGinline dialogDesc-BGinline"}
                                            style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                            <Grid container >
                                            <Grid item xs={12} className="" >
                                                <img loading="lazy" src="https://www.phoenix.edu/content/dam/edu/request-info/img/businessGuideInline.png" alt="Headshot of Felicia E. - MBA 2008, wearing a black blazer and a smile" id="inlineBGImg" className=""/>
                                            </Grid>
                                        {!isSubmit
                                            ? (
                                                <>  
                                                    
                                                    <Grid item xs={12} className ="rfiabandonmentcopy" style={{ width:'-webkit-fill-available', padding:'2rem' }}>
                                                        {unescapeHTML(titleText)}
                                                        {unescapeHTML(descriptionText)}
                                                        <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                        <MaterialInput
                                                            name="fullName"
                                                            id="fullName-BGinline"
                                                            label="First and Last name *"
                                                            ariaRequired="true"
                                                            ariaLabel="Enter your full name"
                                                            variant="outlined"
                                                            className='uopx-input footeremail modalfield'
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                        />
                                                        <MaterialInput name="emailAddress" id="emailAddress-BGinline" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" className='uopx-input footeremail modalfield' />
                                                        <SingleCheckbox
                                                            id="flex_Transfer_Credits_from_ValueExchange__c"
                                                            className='ways2save-checkbox'
                                                            label="I have college credits and may be interested in the Transfer Student Scholarship."
                                                            ariaLabel="I have college credits and may be interested in the Transfer Student Scholarship."
                                                            checkboxName="flex_Transfer_Credits_from_ValueExchange__c"
                                                            parentCallback={this.isTransferCredit}
                                                        />
                                                        <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                        <Button name='submit' size='large' type='submit' id='BGInlineSubmit' className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                    </Grid>
                                                    <ErrorFocus />
                                                </>
                                            ):(
                                                <>
                                                    
                                                    <Grid item xs={12} className ="modalthankscopy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div className ="rfiabandonmentcopy" id="rfiBGinlinecopy">
                                                        {unescapeHTML(thankyouMessageTitle)}
                                                        {unescapeHTML(thankyouMessageDescription)}
                                                        <div style={{ padding: '1.5rem 3rem', display: 'flex', alignItems: 'center', justifyContent:'center' }} >
                                                            <div>
                                                                <Button size='large' id='downloadcta-BGinline' onClick={this.handleDownload} onKeyDown={this.keydownDownload} className='rfi-btn-submit btnclose' ariaLabel='Download now, opens in new window' aria-labelledby='thanksdialogtitle-BGinline thanksdialogdesc-BGinline downloadcta-BGinline'>Download now</Button>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                    </Grid>  
                                                </> 
                                            )}
                                            </Grid>
                                        </Form> 
                                    </Formik>
                                </Grid>
                        </Grid>
                    </Grid>                         
                    </Grid>
                </section>
        {/*    <Footer /> */}
            </div>
        );
    }
}

BusinessGuideInline.defaultProps = {
    classes: {},
};

BusinessGuideInline.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default BusinessGuideInline;
//export default withStyles(styles)(BusinessGuideInline);