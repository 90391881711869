import { profanityCheck } from '../CommonComponents';

const courseCategoryError = 'Course category requires a value';
const currentlyEmployedError = 'Are you currently employed requires a value';
const degreeLevelError = 'Degree level requires a value';
const emailError = 'Email address requires a value';
const employerNameError = 'Employer name requires a value';
const fieldOfStudyError = 'Area of study requires a value';
const firstNameError = 'First name requires a value';
const invalidEmailError = 'Email address appears invalid';
const invalidFirstNameError = 'First name appears invalid';
const invalidLastNameError = 'Last name appears invalid';
const invalidZipError = 'ZIP code appears invalid';
const lastNameError = 'Last name requires a value';
const learningPreferanceError = 'How do you prefer to learn requires a value';
const longFirstNameError = 'First name cannot exceed 20 characters';
const longLastNameError = 'Last name cannot exceed 80 characters';
const militaryError = 'U.S. Military affiliation requires a value';
const nonNumericZipError = 'ZIP code must be numeric';
const phoneNumberError = 'Phone number requires a value';
const registeredNurseError = 'Registered nurse requires a value';
const scholarshipError = 'Are you interested in learning about scholarships requires a value';
const shortLongPhoneNumberError = 'Please enter a ten digit USA phone number. Numeric digits only.';
const shortZipError = 'ZIP codes must include 5 numeric characters';
const zipCodeError = 'Enter a ZIP code';




export function validate(values, step, variant) {
  const errors = {};
  switch (step) {
    case 1:
      if (!values.modality) {
        errors.modality = learningPreferanceError;
      }
      break;
    case 2:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = registeredNurseError;
      }
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }
      break;
    case 3:
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      break;
    case 4:
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      break;
    case 5:
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }

      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }

      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }

      if (variant === 'v4') {
        if (values.flex_isEmployed__c === '' || values.flex_isEmployed__c === undefined) {
          errors.flex_isEmployed__c = currentlyEmployedError;
        }
        if ((values.flex_isEmployed__c === true && !values.flex_Employer_Disclosed_on_RFI__c)) {
          errors.flex_Employer_Disclosed_on_RFI__c = employerNameError;
        }
      }
      break;
    default:
      break;
  }
  return errors;
}

export function validateMobileRFI(values, step, variant) {
  const errors = {};
  switch (step) {
    case 1:
      if (!values.isScholarship) {
        errors.isScholarship = scholarshipError;
      }
      break;
    case 2:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = registeredNurseError;
      }
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }
      break;
    case 3:
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      break;
    case 4:
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      break;
    case 5:
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }

      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }

      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }

      if (variant === 'v4') {
        if (values.flex_isEmployed__c === '' || values.flex_isEmployed__c === undefined) {
          errors.flex_isEmployed__c = currentlyEmployedError;
        }
        if ((values.flex_isEmployed__c === true && !values.flex_Employer_Disclosed_on_RFI__c)) {
          errors.flex_Employer_Disclosed_on_RFI__c = employerNameError;
        }
      }
      break;
    default:
      break;
  }
  return errors;
}

export function validateOneStep(values) {
  const errors = {};
  if (!values.fieldOfStudy) {
    errors.fieldOfStudy = fieldOfStudyError;
  }
  if (values.fieldOfStudy !== 'nondegree' && !values.degreeLevel) {
    errors.degreeLevel = degreeLevelError;
  }
  if (values.fieldOfStudy === 'nondegree' && !values.flex_Course_Category__c) {
    errors.flex_Course_Category__c = courseCategoryError;
  }
  if (!values.firstName) {
    errors.firstName = firstNameError;
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z ]*$/)) {
    errors.firstName = invalidFirstNameError;
  }
  if (values.firstName && values.firstName.match(/^[A-Za-z ]*$/) && values.firstName.length > 20) {
    errors.firstName = longFirstNameError;
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = invalidFirstNameError;
    }
  }
  if (!values.lastName) {
    errors.lastName = lastNameError;
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z ]*$/)) {
    errors.lastName = invalidLastNameError;
  }
  if (values.lastName && values.lastName.match(/^[A-Za-z ]*$/) && values.lastName.length > 80) {
    errors.lastName = longLastNameError;
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = invalidLastNameError;
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = emailError;
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = invalidEmailError;
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = phoneNumberError;
  }
  if (values.phoneNumber && values.phoneNumber.length !== 10) {
    errors.phoneNumber = shortLongPhoneNumberError;
  }
  if (!values.postalCode) {
    errors.zipcode = zipCodeError;
  }
  if (values.postalCode && values.postalCode.length !== 5) {
    errors.zipcode = shortZipError;
  }
  if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
    errors.zipcode = nonNumericZipError;
  } else if (values.postalCode && values.stateProvince === 'N/A') {
    errors.zipcode = invalidZipError;
  }
  if (values.isMilitary === '' || values.isMilitary === undefined) {
    errors.isMilitary = militaryError;
  }
  if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
    errors.isRegisteredNurse = registeredNurseError;
  }
  return errors;
}

export function validateOneStepTest(values) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = firstNameError;
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z ]*$/)) {
    errors.firstName = invalidFirstNameError;
  }
  if (values.firstName && values.firstName.match(/^[A-Za-z ]*$/) && values.firstName.length > 20) {
    errors.firstName = longFirstNameError;
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = invalidFirstNameError;
    }
  }
  if (!values.lastName) {
    errors.lastName = lastNameError;
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z ]*$/)) {
    errors.lastName = invalidLastNameError;
  }
  if (values.lastName && values.lastName.match(/^[A-Za-z ]*$/) && values.lastName.length > 80) {
    errors.lastName = longLastNameError;
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = invalidLastNameError;
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = emailError;
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = invalidEmailError;
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = phoneNumberError;
  }
  if (values.phoneNumber && values.phoneNumber.length !== 10) {
    errors.phoneNumber = shortLongPhoneNumberError;
  }
  if (!values.postalCode) {
    errors.zipcode = zipCodeError;
  }
  if (values.postalCode && values.postalCode.length !== 5) {
    errors.zipcode = shortZipError;
  }
  if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
    errors.zipcode = nonNumericZipError;
  } else if (values.postalCode && values.stateProvince === 'N/A') {
    errors.zipcode = invalidZipError;
  }
  if (values.isMilitary === '' || values.isMilitary === undefined) {
    errors.isMilitary = militaryError;
  }
  return errors;
}

export function validateTwoStep(values, step, variant) {
  const errors = {};
  switch (step) {
    case 0:
      if (!values.modality) {
        errors.modality = learningPreferanceError;
      }
      break;
    case 1:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = registeredNurseError;
      }
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      break;
    case 2:
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }

      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }
      if (variant === 'v4') {
        if (values.flex_isEmployed__c === '' || values.flex_isEmployed__c === undefined) {
          errors.flex_isEmployed__c = currentlyEmployedError;
        }
        if ((values.flex_isEmployed__c === true && !values.flex_Employer_Disclosed_on_RFI__c)) {
          errors.flex_Employer_Disclosed_on_RFI__c = employerNameError;
        }
      }
      break;
    default:
      break;
  }
  return errors;
}

export function validateThreeStep(values, step) {
  const errors = {};
  switch (step) {
    case 0:
      if (!values.modality) {
        errors.modality = learningPreferanceError;
      }
      break;
    case 1:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = registeredNurseError;
      }
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }
      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }
      // if (values.isScholarship === '' || values.isScholarship === undefined) {
      //   errors.isScholarship = 'Know about scholarships requires a value';
      // }
      break;
    case 2:
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      break;
    case 3:
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }
      break;
    default:
      break;
  }
  return errors;
}
export function validate3Step(values, step) {
  const errors = {};
  switch (step) {
    case 0:
      if (!values.modality) {
        errors.modality = learningPreferanceError;
      }
      break;
    case 1:
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }

      // if (values.isScholarship === '' || values.isScholarship === undefined) {
      //   errors.isScholarship = 'Know about scholarships requires a value';
      // }
      break;
    case 2:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = registeredNurseError;
      }
      break;
    case 3:
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }
      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }
      break;
    default:
      break;
  }
  return errors;
}
export function validate5Step(values, step) {
  const errors = {};
  switch (step) {
    case 0:
      if (!values.modality) {
        errors.modality = learningPreferanceError;
      }
      break;
    case 1:
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }
      break;
    case 2:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = registeredNurseError;
      }
      break;
    case 3:
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      break;
    case 4:
      break;
    case 5:
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }
      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }
    default:
      break;
  }
  return errors;
}
export function validateDynamic5Step(values, step, degreeFlag) {
  const errors = {};
  switch (step) {
    case 0:
      if (!values.modality) {
        errors.modality = learningPreferanceError;
      }
      break;
    case 1:      
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = fieldOfStudyError;
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
          errors.isRegisteredNurse = registeredNurseError;
        }
      break;
    case 2:
      if (!values.degreeLevel && degreeFlag === true) {
        errors.degreeLevel = degreeLevelError;
      }
      else if (!values.flex_Course_Category__c && degreeFlag === false) {
        errors.flex_Course_Category__c = courseCategoryError;
      }
      break;
    case 3:
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      break;
    case 4:
      break;
    case 5:
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }
      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }
    default:
      break;
  }
  return errors;
}
export function ContinuingEducationValidation(values) {
  const errors = {};
  if (!values.flex_Course_Category__c) {
    errors.flex_Course_Category__c = courseCategoryError;
  }
  if (!values.degreeLevel) {
    errors.degreeLevel = degreeLevelError;
  }
  if (!values.firstName) {
    errors.firstName = firstNameError;
  }
  if (values.firstName && !values.firstName.match(/^[A-Za-z ]*$/)) {
    errors.firstName = invalidFirstNameError;
  }
  if (values.firstName && values.firstName.match(/^[A-Za-z ]*$/) && values.firstName.length > 20) {
    errors.firstName = longFirstNameError;
  }
  if (values.firstName) {
    const check = profanityCheck(values.firstName);
    if (check === false) {
      errors.firstName = invalidFirstNameError;
    }
  }
  if (!values.lastName) {
    errors.lastName = lastNameError;
  }
  if (values.lastName && !values.lastName.match(/^[A-Za-z ]*$/)) {
    errors.lastName = invalidLastNameError;
  }
  if (values.lastName && values.lastName.match(/^[A-Za-z ]*$/) && values.lastName.length > 80) {
    errors.lastName = longLastNameError;
  }
  if (values.lastName) {
    const check = profanityCheck(values.lastName);
    if (check === false) {
      errors.lastName = invalidLastNameError;
    }
  }
  if (!values.emailAddress) {
    errors.emailAddress = emailError;
  }
  if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    errors.emailAddress = invalidEmailError;
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = phoneNumberError;
  }
  if (values.phoneNumber && values.phoneNumber.length !== 10) {
    errors.phoneNumber = shortLongPhoneNumberError;
  }
  if (!values.postalCode) {
    errors.zipcode = zipCodeError;
  }
  if (values.postalCode && values.postalCode.length !== 5) {
    errors.zipcode = shortZipError;
  }
  if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
    errors.zipcode = nonNumericZipError;
  } else if (values.postalCode && values.stateProvince === 'N/A') {
    errors.zipcode = invalidZipError;
  }
  if (values.isMilitary === '' || values.isMilitary === undefined) {
    errors.isMilitary = militaryError;
  }
  if ((values.flex_Course_Category__c === 'nursing' || values.flex_Course_Category__c === 'healthcare') && !values.isRegisteredNurse) {
    errors.isRegisteredNurse = registeredNurseError;
  }
  return errors;
}

export function validateMobileContEdForm(values, step) {
  const errors = {};
  switch (step) {
    case 1:
      if (!values.flex_Course_Category__c) {
        errors.flex_Course_Category__c = courseCategoryError;
      }
      if (!values.degreeLevel) {
        errors.degreeLevel = degreeLevelError;
      }
      if (!values.postalCode) {
        errors.zipcode = zipCodeError;
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = shortZipError;
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = nonNumericZipError;
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = invalidZipError;
      }
      break;
    case 2:
      if (!values.emailAddress) {
        errors.emailAddress = emailError;
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = invalidEmailError;
      }
      if (!values.firstName) {
        errors.firstName = firstNameError;
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = invalidFirstNameError;
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = longFirstNameError;
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = invalidFirstNameError;
        }
      }

      if (!values.lastName) {
        errors.lastName = lastNameError;
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = invalidLastNameError;
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = longLastNameError;
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = invalidLastNameError;
        }
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = phoneNumberError;
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = shortLongPhoneNumberError;
      }

      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = militaryError;
      }
      break;
    default:
      break;
  }
  return errors;
}
